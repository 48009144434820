import React, { useState, useRef, useEffect } from "react";
import { Button, Container, ProgressBar, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CameraRecorder from "./CameraRecorder";
import Webcam from "react-webcam";
import { Question } from "./types"; // Import the shared Question interface

interface InterviewTemplateProps {
    type: string;
    questions: Question[];
}

// Define the ref type for CameraRecorder if it provides instance methods
interface CameraRecorderRef {
    stopAndDownload: () => void; 
    // Add other methods from CameraRecorder if needed
}

export default function InterviewTemplate({
    type,
    questions,
}: InterviewTemplateProps): JSX.Element {
    const navigate = useNavigate();

    // Use the defined CameraRecorderRef type
    const cameraRecorderRef = useRef<CameraRecorderRef | null>(null);

    const [isQuestionsBegun, setIsQuestionsBegun] = useState<boolean>(false);
    const [questionsAsked, setQuestionsAsked] = useState<Question[]>([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(-1);
    const numQuestionsToAsk = questionsAsked.length;
    const [showExitModal, setShowExitModal] = useState<boolean>(false);

    useEffect(() => {
        setQuestionsAsked(questions);
    }, [questions]);

    const startInterview = () => {
        setCurrentQuestionIndex(0);
        setIsQuestionsBegun(true);
    };

    const exitPage = () => {
        if (cameraRecorderRef.current) {
            cameraRecorderRef.current.stopAndDownload();
        } else {
            console.log("cameraRecorderRef is null");
        }
        setTimeout(() => navigate("/test-center/home"), 1000);
    };

    const handleExitClick = () => {
        setShowExitModal(true);
    };

    const handleCloseExitModal = () => {
        setShowExitModal(false);
    };

    const handleConfirmExit = () => {
        handleCloseExitModal();
        exitPage();
    };

    const handleNextQuestion = () => {
        if (currentQuestionIndex === questionsAsked.length - 1) {
            setTimeout(() => setCurrentQuestionIndex((prevIndex) => prevIndex + 1), 1000);
            setTimeout(exitPage, 2000);
        } else {
            setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
        }
    };

    return (
        <Container fluid className="min-vh-100 py-4" style={{ backgroundColor: "#ffffff" }}>
            <div className="mb-3 flex flex-col items-start gap-y-4">
                <h1 className="text-3xl font-medium text-left">{type} Exam</h1>
                <div className="flex flex-row items-center gap-x-3">
                    <ProgressBar
                        now={
                            numQuestionsToAsk > 0
                                ? ((currentQuestionIndex + 1) / numQuestionsToAsk) * 100
                                : 0
                        }
                        variant="success"
                        style={{
                            height: "8px",
                            width: "200px",
                            backgroundColor: "#bbb",
                        }}
                    />
                    {isQuestionsBegun ? (
                        <p>
                            <span className="font-semibold">
                                {currentQuestionIndex + 1}/{questionsAsked.length}
                            </span>{" "}
                            questions
                        </p>
                    ) : (
                        <p>Test not started yet</p>
                    )}
                </div>
            </div>
            <div className="bg-white shadow-lg rounded-3xl p-4">
                {isQuestionsBegun ? (
                    currentQuestionIndex < questionsAsked.length ? (
                        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                            <div className="lg:col-span-2">
                                <h5 className="mb-3 text-lg font-semibold">
                                    Question {currentQuestionIndex + 1}
                                </h5>
                                <p className="mb-4 text-gray-800">
                                    {questionsAsked[currentQuestionIndex].question}
                                </p>
                            </div>
                            <div className="lg:col-span-1">
                                <div className="webcam-container">
                                    <CameraRecorder
                                        isBackground={true}
                                        ref={cameraRecorderRef}
                                        // Convert string IDs to numbers if required, e.g., Number(question.id)
                                        ids={questionsAsked.map((question) => Number(question.id))}
                                        type="background"
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="text-center">
                            <p className="text-lg leading-normal tracking-tight py-10">
                                You have completed all questions!
                            </p>
                        </div>
                    )
                ) : (
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                        <div className="prose lg:col-span-2">
                            <h5 className="mb-4 font-semibold text-black">Instructions</h5>
                            <ol className="space-y-2 text-sm text-gray-800 tracking-tight -ml-2">
                                <li className="list-decimal">
                                    You will be given a set of {type.toLowerCase()} interview questions. Please answer each question thoughtfully, focusing on expressing yourself honestly and authentically.
                                </li>
                                <li className="list-decimal">
                                    Use your background, experiences, and personality to help us understand who you are and how you approach challenges.
                                </li>
                                <li className="list-decimal">
                                    Submit each answer when you are fully done with that question and would like to move on to the next.
                                    <span className="italic ml-1">
                                        (Note: Take as much or as little time as you'd like with each question)
                                    </span>
                                </li>
                            </ol>
                        </div>
                        <div className="lg:col-span-1 m-auto lg:mx-0">
                            <div className="webcam-container lg:float-right">
                                <Webcam className="rounded w-full max-w-[300px]" />
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="py-4 flex flex-col sm:flex-row justify-end gap-3">
                {isQuestionsBegun && (
                    <button
                        className="rounded-full px-4 py-1.5 border-1.5 border-solid border-special-blue text-special-blue font-base text-sm"
                        onClick={handleExitClick}
                    >
                        Exit Test
                    </button>
                )}
                {!isQuestionsBegun ? (
                    <Button
                        onClick={startInterview}
                        variant="primary"
                        className="bg-special-blue border-0 rounded-full text-sm px-4"
                    >
                        Start Interview
                    </Button>
                ) : (
                    currentQuestionIndex < questionsAsked.length && (
                        <Button
                            variant="primary"
                            className="bg-special-blue border-0 rounded-full text-sm px-4"
                            onClick={handleNextQuestion}
                        >
                            {currentQuestionIndex === questionsAsked.length - 1
                                ? "Submit Test"
                                : "Next"}
                        </Button>
                    )
                )}
            </div>

            {/* Exit Confirmation Modal */}
            <Modal show={showExitModal} onHide={handleCloseExitModal} centered>
                <Modal.Body className="p-4">
                    <h4 className="mb-4 text-lg font-medium text-black">Are you sure?</h4>
                    <p className="mb-2 text-black tracking-tight text-sm">
                        Exiting the test will end your interview and cause you to lose all your
                        progress!
                    </p>
                    <div className="d-flex justify-content-center gap-3 float-right">
                        <Button
                            variant="secondary"
                            onClick={handleCloseExitModal}
                            size="sm"
                            className="px-4 py-1 rounded-pill"
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            onClick={handleConfirmExit}
                            size="sm"
                            className="px-4 py-1 rounded-pill bg-special-blue border-0"
                        >
                            I'm sure
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </Container>
    );
}
