import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../routes/home/Header";
import axiosInterceptor from "../utils/axiosInterceptor";
import Footer from "../routes/home/Footer";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";


const RootTemplate: React.FC = () => {
    const location = useLocation();
    const [isUserLoggedIn, setUserLoggedIn] = useState<boolean>(false);
    const [pendingStateInfo, setPendingStateInfo] = useState<boolean>(true);
    const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(
        window.innerWidth >= 1024
    );

    useEffect(() => {
        const accessToken = localStorage.getItem("access_token");
        if (accessToken) {
            setUserLoggedIn(true);
        }
        setPendingStateInfo(false);

        if (
            window &&
            (window.location.pathname === "/" || window.location.pathname === "") &&
            window.location.hash
        ) {
            setTimeout(() => {
                const elementToScrollTo = document.querySelector(
                    window.location.hash
                );
                elementToScrollTo?.scrollIntoView();
            }, 100);
        }
    }, []);

    const lastPolledForVerification = localStorage.getItem(
        "lastPolledForVerification"
    );
    const VERIFICATION_CHECKING_THRESHOLD = 1 * 60 * 1000; // 1 minute

    useEffect(() => {
        if (
            !lastPolledForVerification ||
            new Date().getTime() - parseInt(lastPolledForVerification) >
                VERIFICATION_CHECKING_THRESHOLD
        ) {
            const userId = localStorage.getItem("user_id");
            if (userId) {
                localStorage.setItem(
                    "lastPolledForVerification",
                    new Date().getTime().toString()
                );
                axiosInterceptor
                    .get(`/getuser/${userId}/`)
                    .then((response) => {
                        localStorage.setItem(
                            "is_verified_by_algolink",
                            response.data["u_data"]["is_verified_by_algolink"]
                        );
                        localStorage.setItem(
                            "is_algolink_admin",
                            response.data["u_data"]["is_algolink_admin"]
                        );
                        localStorage.setItem(
                            "user_name",
                            `${response.data["u_data"]["firstname"]} ${response.data["u_data"]["lastname"]}`
                        );
                    })
                    .catch((error) => {
                        console.error("Error fetching user verification:", error);
                    });
            }
        }
    }, [lastPolledForVerification, VERIFICATION_CHECKING_THRESHOLD]);

    const SwitchBetween = (
        <Nav className="flex flex-row pl-4 bg-black" id="switchBetween">
            <Nav.Link as={Link} target="_blank" to="https://algolink.net">
                <button
                    className="text-white underline"
                    style={{
                        textDecorationThickness: "0.25rem",
                        textUnderlineOffset: "0.75rem",
                        fontFamily: "Poppins",
                    }}
                >
                    Get Referrals
                </button>
            </Nav.Link>
            <Nav.Link
                as={Link}
                target="_blank"
                to="https://enterprise.algolink.net"
            >
                <button
                    className="text-white hover:underline"
                    style={{
                        textDecorationThickness: "0.25rem",
                        textUnderlineOffset: "0.75rem",
                        fontFamily: "Poppins",
                    }}
                >
                    Give Referrals
                </button>
            </Nav.Link>
        </Nav>
    );

    const toggleSidebar = (state: boolean) => {
        setIsSidebarOpen(state);
    };

    return pendingStateInfo ? (
        <div className="flex justify-center w-full h-screen items-center">
            <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    ) : (
        <div className="flex flex-col h-screen">
            {SwitchBetween}
            {!isUserLoggedIn && (
                <Header
                    isUserLoggedIn={isUserLoggedIn}
                    isSidebarOpen={isSidebarOpen}
                    toggleSidebar={toggleSidebar}
                />
            )}
            <div className="flex flex-grow overflow-hidden">
                {isUserLoggedIn && (
                    <div className="h-full">
                        <Header
                            isUserLoggedIn={isUserLoggedIn}
                            isSidebarOpen={isSidebarOpen}
                            toggleSidebar={toggleSidebar}
                        />
                    </div>
                )}
                <div className="flex-grow flex flex-col overflow-hidden h-full">
                    <div
                        className="flex-grow flex-col overflow-y-auto h-full"
                        style={{
                            backgroundColor: "#ffffff",
                            paddingLeft: isUserLoggedIn ? "20px" : "0px",
                            paddingRight: isUserLoggedIn ? "20px" : "0px",
                            paddingTop: isUserLoggedIn ? "24px" : "0px",
                            paddingBottom: isUserLoggedIn ? "20px" : "0px",
                            transition: "padding-left 0.25s ease-out",
                        }}
                    >
                        <Outlet />
                        <div className="flex-grow"></div>
                        {!isUserLoggedIn && location.pathname === "/" && (
                            <Footer />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RootTemplate;