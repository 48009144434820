// src/routes/profile/career/Skills.tsx

import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Typeahead } from "react-bootstrap-typeahead";
import axiosInterceptor from "../../../utils/axiosInterceptor";
import possibleSkills from "../../../lib/skills";
import { Button } from "react-bootstrap";
import { Skill, CreateSkillProps } from "../types";

const SkillsComponent: React.FC<{ skills: Skill[] }> = ({ skills }) => {
  return (
    <div className="flex flex-wrap flex-row gap-2">
      {skills.map((skill, i) => (
        <button
          className="bg-[#ADC4FF] bg-opacity-20 text-black rounded py-1 px-2.5 w-auto text-sm"
          key={i}
        >
          {skill.name}
        </button>
      ))}
    </div>
  );
};

const CreateSkill: React.FC<CreateSkillProps> = ({ skills, setSkills }) => {
  const options: Skill[] = possibleSkills; 
  const [selectedSkills, setSelectedSkills] = useState<Skill[]>(skills);

  // Handle changes in the Typeahead component
  const handleSkillsChange = (selected: Array<any>) => {
    const converted: Skill[] = selected.map((item) => {
      if (typeof item === "string") {
        return { name: item };
      } else if ("name" in item) {
        return { name: item.name };
      }
      return { name: String(item) };
    });
    setSelectedSkills(converted);
  };

  const handleAdd = async (itemToAdd: Skill) => {
    const userId = localStorage.getItem("user_id");
    const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1];
    try {
      await axiosInterceptor.post(`/create_skill/${userId}/`, itemToAdd, {
        headers: { "Content-Type": "application/json", "X-CSRFToken": csrfToken || "" },
      });
      await axiosInterceptor.post(`/create_skill_vec/${userId}/`, itemToAdd, {
        headers: { "Content-Type": "application/json", "X-CSRFToken": csrfToken || "" },
      });
    } catch (error) {
      console.error("Error adding skill:", error);
    }
  };

  const handleDelete = async (itemToRemove: Skill) => {
    const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1];
    const skillToDelete = skills.find((s) => s.name === itemToRemove.name);
    if (skillToDelete && skillToDelete.id) {
      try {
        await axiosInterceptor.delete(`/delete_skill/${skillToDelete.id}/`, {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken || "",
          },
        });
      } catch (error) {
        console.error("Error deleting skill:", error);
      }
    }
  };

  const handleSave = async () => {
    const addedSkills = selectedSkills.filter(
      (skill) => !skills.some((existing) => existing.name === skill.name)
    );
    const removedSkills = skills.filter(
      (existing) => !selectedSkills.some((skill) => skill.name === existing.name)
    );

    try {
      await Promise.all([
        ...addedSkills.map(handleAdd),
        ...removedSkills.map(handleDelete),
      ]);
      setSkills(selectedSkills);
      console.log("All skills updated successfully");
    } catch (error) {
      console.error("Error updating skills:", error);
    }
  };

  return (
    <Form onSubmit={(e) => e.preventDefault()}>
      <Stack gap={2}>
        <Form.Group className="m-0">
          <Typeahead
            id="skill-select"
            labelKey="name"
            multiple
            onChange={handleSkillsChange}
            options={options}
            placeholder="Select your skills"
            selected={selectedSkills}
            allowNew
            newSelectionPrefix="Add a new skill: "
          />
          <Button onClick={handleSave} className="ml-auto mr-0 mt-4 float-end" variant="primary">
            <i className="bi bi-floppy mr-2"></i>
            Save
          </Button>
        </Form.Group>
      </Stack>
    </Form>
  );
};

export { SkillsComponent as Skills, CreateSkill };