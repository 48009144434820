import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";

interface TimerProps {
    onTimeUp?: () => void;
    tiny?: boolean;
    circular?: boolean;
}

const Timer: React.FC<TimerProps> = ({ onTimeUp, tiny = false }) => {
    const [seconds, setSeconds] = useState<number>(60 * 60);

    useEffect(() => {
        if (seconds > 0) {
            const interval = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);

            return () => clearInterval(interval);
        } else if (seconds === 0 && onTimeUp) {
            onTimeUp();
        }
    }, [seconds, onTimeUp]);

    const formatTime = (totalSeconds: number): string => {
        const minutes = Math.floor(totalSeconds / 60);
        const secs = totalSeconds % 60;
        return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
    };

    const calculateProgress = (): number => {
        const totalTime = 60 * 60;
        return (seconds / totalTime) * 100;
    };

    if (tiny) {
        const progress = calculateProgress();
        const circumference = 2 * Math.PI * 20; // 20 is the radius of the circle
        const dashOffset = circumference * (1 - progress / 100);

        return (
            <div className="timer-tiny">
                <svg width="50" height="50" viewBox="0 0 50 50">
                    <circle
                        cx="25"
                        cy="25"
                        r="20"
                        fill="white"
                        stroke="#3498db"
                        strokeWidth="2"
                    />
                    <circle
                        cx="25"
                        cy="25"
                        r="20"
                        fill="none"
                        stroke="#3498db"
                        strokeWidth="2"
                        strokeDasharray={circumference}
                        strokeDashoffset={dashOffset}
                        transform="rotate(-90 25 25)"
                    />
                </svg>
                <span className="timer-tiny-text">{formatTime(seconds)}</span>
            </div>
        );
    }

    return (
        <div className="timer">
            <h3>
                <FontAwesomeIcon
                    className="clock"
                    icon={faClock}
                    style={{ color: "#ffffff" }}
                />
                {formatTime(seconds)}
            </h3>
        </div>
    );
};

export default Timer;