import React, { useState, CSSProperties, MouseEvent } from "react";

const defaultStarCount = 5;

interface StarIconProps {
    filled: boolean;
    half: boolean;
    color: string;
}

const StarIcon: React.FC<StarIconProps> = ({ filled, half, color }) => {
    const gradientId = `grad-vertical`;
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke={color}
            fill={filled ? color : half ? `url(#${gradientId})` : "none"}
        >
            <defs>
                <linearGradient
                    id={gradientId}
                    x1={"0%"}
                    y1={"0%"}
                    x2={"100%"}
                    y2={"0%"}
                >
                    <stop offset="50%" stopColor={color} />
                    <stop offset="50%" stopColor="transparent" />
                </linearGradient>
            </defs>
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z"
            />
        </svg>
    );
};

interface StarRatingProps {
    onClick?: (rating: number) => void;
    totalStars?: number;
    activeStars?: number;
    containerStyle?: CSSProperties;
    starStyle?: CSSProperties;
    activeColor?: string;
    inactiveColor?: string;
    disabled?: boolean;
}

const StarRating: React.FC<StarRatingProps> = ({
    totalStars = defaultStarCount,
    activeStars = 0,
    containerStyle = {},
    starStyle = {},
    activeColor = "gold",
    inactiveColor = "gray",
    disabled = false,
    onClick = () => { return 0; },
    }) => {
    const [totalActiveStars, setTotalActiveStars] = useState<number>(activeStars);
    const [onHoverActiveStars, setOnHoverActiveStars] = useState<number>(0);

    const onClickStar = (sCount: number) => {
        setTotalActiveStars(sCount);
        onClick(sCount);
    };

    const handleMouseMove = (e: MouseEvent<HTMLDivElement>) => {
        if (disabled) return;

        const rect = e.currentTarget.getBoundingClientRect();
        const starWidth = rect.width / totalStars;

        const position = e.clientX - rect.left;
        const starIndex = Math.floor(position / starWidth);
        const fraction = (position % starWidth) / starWidth;

        const rating = starIndex + (fraction > 0.5 ? 1 : 0.5);
        setOnHoverActiveStars(Math.min(Math.max(rating, 0.5), totalStars));
    };

    const handleMouseLeave = () => {
        setOnHoverActiveStars(0);
    };

    const renderStar = (index: number) => {
        const activeRating =
            onHoverActiveStars > 0 ? onHoverActiveStars : totalActiveStars;
        const isFilled = index + 1 <= activeRating;
        const isHalfFilled = !isFilled && index + 0.5 <= activeRating;

        return (
            <span
                key={index}
                data-count={index}
                style={{
                    ...starStyle,
                    display: "inline-block",
                    margin: "2px",
                }}
            >
                <StarIcon
                    filled={isFilled}
                    half={isHalfFilled}
                    color={
                        isFilled || isHalfFilled ? activeColor : inactiveColor
                    }
                />
            </span>
        );
    };

    return (
        <div style={{ width: "fit-content", margin: 1, ...containerStyle }}>
            <div
                style={{
                    display: "flex",
                    cursor: !disabled ? "pointer" : "default",
                    userSelect: "none",
                    flexDirection: "row",
                }}
                onClick={() => !disabled && onClickStar(onHoverActiveStars)}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
            >
                {Array.from({ length: totalStars }, (_, i) => renderStar(i))}
            </div>
        </div>
    );
};

export default StarRating;