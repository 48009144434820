// src/components/DateInput.tsx

import React from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";

interface DateInputProps {
  sd: string; // Start Date
  ed?: string; // End Date, optional
  pos?: boolean; // Position or other flag, optional
  handler: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
}

export const DateInput: React.FC<DateInputProps> = ({ sd, ed, pos, handler }) => {
  return (
    <div>
      <FloatingLabel label="Start Date">
        <Form.Control
          type="date"
          name="start_date"
          value={sd}
          onChange={handler}
        />
      </FloatingLabel>
      <FloatingLabel label="End Date" className="mt-2">
        <Form.Control
          type="date"
          name="end_date"
          value={ed || ""}
          onChange={handler}
        />
      </FloatingLabel>
    </div>
  );
};

// import React, { useState } from "react";
// import Form from "react-bootstrap/Form";
// import Col from "react-bootstrap/Col";
// import Row from "react-bootstrap/Row";

// export function DateInput({ pos, id, sd, ed, handler }) {
//     const is_ed = (ed === null)
//     const [checked, setChecked] = useState(is_ed);
//     function handleCheck(e) {
//       setChecked(!checked);
//       if (e.target.checked) {
//         handler({ target: { name: "end_date", value: "" } });
//       }
//     }
//     return (
//       <div>
//         {pos ? (
//           <Form.Check
//             id={`date-${id}`}
//             label={`This is my current position`}
//             onChange={handleCheck}
//             value={checked}
//             type = "checkbox"
//           />
//         ) : (
//           <></>
//         )}
//         <Row>
//           <Form.Group controlId={`start-date-${id}`} as={Col}>
//             <Form.Label className="m-0">Start Date</Form.Label>
//             <Form.Control
//               type="date"
//               name="start_date"
//               placeholder="Start Date"
//               onChange={handler}
//               value={sd}
//             />
//           </Form.Group>
//           {!checked ? (
//             <Form.Group controlId={`end-date-${id}`} as={Col}>
//               <Form.Label className="m-0">End Date</Form.Label>
//               <Form.Control
//                 type="date"
//                 name="end_date"
//                 placeholder="End Date"
//                 onChange={handler}
//                 value={ed}
//               />
//             </Form.Group>
//           ) : null}
//         </Row>
//       </div>
//     );
//   }