// src/routes/course_recommender/CoursePath.tsx

import React, { useEffect, useRef, useState } from "react";
import ReactFlow, { useReactFlow, Background, Node, Edge, ReactFlowInstance } from "react-flow-renderer";
import { useNavigate } from "react-router-dom";
import "./CoursePath.css";
import axiosInterceptor from "../../utils/axiosInterceptor";
import { Modal, Button } from "react-bootstrap";
import { Course, CourseProgress, CoursePathProps } from "./types";

// Define the CoursePath component with typed props
const CoursePath: React.FC<CoursePathProps> = ({
    nodes,
    edges,
    title,
    courses,
    coursesProgress,
    reFetchData,
}) => {
    const { fitView } = useReactFlow();
    const reactFlowInstance = useRef<ReactFlowInstance | null>(null);
    const reactFlowWrapper = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();

    const [show, setShow] = useState<boolean>(false);
    const [currentNode, setCurrentNode] = useState<Node | null>(null);
    const [currentCourse, setCurrentCourse] = useState<CourseProgress | null>(null);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const onNodeClick = (_event: React.MouseEvent, node: Node) => {
        const courseId = node.data.label as string;
        const selectedCourse = courses.find((course) => course.id === courseId);
        const courseProg = coursesProgress.find(
            (course) => course.courseNumber === selectedCourse?.courseNumber
        );

        setCurrentNode(node);
        setCurrentCourse(courseProg || null);
        handleShow();
    };

    const handleAction1 = async (prog = 1.0) => {
        if (!currentNode) return;

        const courseId = currentNode.data.label as string;
        const course = courses.find((course) => course.id === courseId);
        if (!course) return;

        const user_id = localStorage.getItem("user_id");
        await axiosInterceptor.patch(
            `course-progress/update/${user_id}/`,
            {
                updates: [
                    {
                        courseNumber: course.courseNumber,
                        progress: prog,
                    },
                ],
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        reFetchData();
        handleClose();
        window.location.reload();
    };

    const handleAction2 = () => {
        if (!currentNode) return;

        const courseId = currentNode.data.label as string;
        const course = courses.find((course) => course.id === courseId);
        if (course) {
            const url = `/courses/${courseId}/lectures/1`;
            navigate(url);
        }

        handleClose();
    };

    useEffect(() => {
        if (reactFlowInstance.current) {
            reactFlowInstance.current.fitView({ padding: 0.2 });
        }
    }, [fitView]);

    return (
        <div className="h-full">
            {/* Key Section */}
            <div className="rounded-xl absolute border border-gray-600 border-opacity-0 bg-white p-2.5 z-10 bottom-4 lg:bottom-auto left-4 lg:left-auto lg:top-4 lg:right-4">
                <h4 className="font-semibold absolute -top-3 left-2 bg-white p-1">Key</h4>
                <div className="flex items-center -mb-1 mt-2">
                    <div
                        className="rounded"
                        style={{
                            width: "28px",
                            height: "28px",
                            backgroundColor: "#d1e7ff",
                            marginRight: "10px",
                        }}
                    ></div>
                    <p className="font-medium mt-2 !text-sm">Mastered</p>
                </div>
                <div className="flex items-center -mb-1">
                    <div
                        className="rounded"
                        style={{
                            width: "28px",
                            height: "28px",
                            backgroundColor: "#CCD9AB",
                            marginRight: "10px",
                        }}
                    ></div>
                    <p className="font-medium mt-2 !text-sm">Recommended</p>
                </div>
                <div className="flex items-center -mb-1">
                    <div
                        className="rounded"
                        style={{
                            width: "28px",
                            height: "28px",
                            backgroundColor: "#fff3cd",
                            marginRight: "10px",
                        }}
                    ></div>
                    <p className="font-medium mt-2 !text-sm">In Progress</p>
                </div>
                <div className="flex items-center -mb-1">
                    <div
                        className="rounded"
                        style={{
                            width: "28px",
                            height: "28px",
                            backgroundColor: "#f8d7da",
                            marginRight: "10px",
                        }}
                    ></div>
                    <p className="font-medium mt-2 !text-sm">Need More Prerequisites</p>
                </div>
            </div>

            {/* Title */}
            <h2>{title}</h2>

            {/* Modal */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Course Actions</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    {currentCourse && (!currentCourse.progress || currentCourse.progress < 1.0) && (
                        <Button variant="secondary" onClick={() => handleAction1(1.0)}>
                            Set as Mastered
                        </Button>
                    )}
                    {currentCourse && currentCourse.progress >= 1.0 && (
                        <Button variant="secondary" onClick={() => handleAction1(0.0)}>
                            Unmaster Course
                        </Button>
                    )}
                    <Button variant="primary" onClick={handleAction2}>
                        Go to Course
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* ReactFlow */}
            <div ref={reactFlowWrapper} style={{ width: "100%", height: "100%" }}>
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    onInit={(instance) => {
                        reactFlowInstance.current = instance;
                        instance.fitView({ padding: 0.2 });
                    }}
                    zoomOnScroll={true}
                    panOnDrag={true}
                    onNodeClick={onNodeClick}
                    style={{ width: "100%", height: "100%" }}
                >
                    <Background />
                </ReactFlow>
            </div>
        </div>
    );
};

export default CoursePath;
