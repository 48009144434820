// src/routes/profile/Profile.tsx
import React, { useState, useEffect } from "react";
import ProfileCard from "./ProfileCard";
import PersonalInfoCard from "./PersonalInfoCard";
import "../../assets/userAssets/main.css";
import CareerProfile from "./CareerProfile";
import axiosInterceptor from "../../utils/axiosInterceptor";
import ScreenSpinner from "../../components/ScreenSpinner";
import SurveyLink from "./surveys/SurveyLink";
import { UserData, DisplayUser } from "./types"; // Import the unified interfaces

const Profile: React.FC = () => {
  const [userdata, setUserData] = useState<UserData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const userId = localStorage.getItem("user_id");

  useEffect(() => {
    const fetchUserData = async () => {
      if (!userId) {
        setIsLoading(false);
        return;
      }
      try {
        const response = await axiosInterceptor.get<{ u_data: Partial<UserData> }>(`/getuser/${userId}/`);

        // Provide fallback for missing fields
        const fetchedData: UserData = {
          UserID: userId,
          firstname: response.data.u_data.firstname ?? "",
          lastname: response.data.u_data.lastname ?? "",
          email: response.data.u_data.email ?? "",
          phone_number: response.data.u_data.phone_number ?? "",
          website: response.data.u_data.website ?? "",
          linkedin: response.data.u_data.linkedin ?? "",
          dob: response.data.u_data.dob ?? "",
          street_addr: response.data.u_data.street_addr ?? "",
          city_addr: response.data.u_data.city_addr ?? "",
          state_addr: response.data.u_data.state_addr ?? "",
          zip_code: response.data.u_data.zip_code ?? "",
          country_addr: response.data.u_data.country_addr ?? "",
          password: response.data.u_data.password ?? "",
          password_verify: response.data.u_data.password_verify ?? "",
          has_visa: typeof response.data.u_data.has_visa === 'boolean'
            ? (response.data.u_data.has_visa ? "Yes" : "No")
            : (response.data.u_data.has_visa ?? "No"),
          job_country: response.data.u_data.job_country ?? [],
          github: response.data.u_data.github ?? "",
        };

        setUserData(fetchedData);
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [userId]);

  if (isLoading) {
    return <ScreenSpinner />;
  }

  if (!userdata) {
    return <div>Error loading user data</div>;
  }

  // Construct DisplayUser from UserData
  const user: DisplayUser = {
    name: `${userdata.firstname} ${userdata.lastname}`,
    email: userdata.email,
    phone: userdata.phone_number,
    website: userdata.website,
    linkedin: userdata.linkedin,
    birthday: userdata.dob,
    address: `${userdata.street_addr}, ${userdata.city_addr}, ${userdata.state_addr}, ${userdata.zip_code}, ${userdata.country_addr}`,
    visa: userdata.has_visa,
    countries: userdata.job_country, // from job_country array
  };

  return (
    <div className="flex flex-row py-4 gap-x-5">
      <div className="w-96 flex flex-col gap-y-3">
        <ProfileCard user={user} user_data={userdata} />
        <PersonalInfoCard user={user} user_data={userdata} />
        <SurveyLink text="Job Preferences" path="/job-preferences-survey" />
        <SurveyLink text="MBTI" path="/personality-survey" />
        <SurveyLink text="DISC" path="/personality-survey" />
      </div>
      <div className="w-full">
        <CareerProfile />
      </div>
    </div>
  );
};

export default Profile;
