import React from "react";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";

// CSS
import "./assets/css/animate.min.css";
import "./assets/css/bootstrap.custom.scss";
import "./assets/css/flaticon.css";
import "./assets/css/fontawesome-all.min.css";
import "./assets/css/gijgo.css";
import "./assets/css/magnific-popup.css";
import "./assets/css/nice-select.css";
import "./assets/css/owl.carousel.min.css";
import "./assets/css/slick.css";
import "./assets/css/slicknav.css";
import "./assets/css/style.css";
import "./assets/css/themify-icons.css";
import "./assets/loginAssets/main.css";
import "./assets/loginAssets/util.css";
import "./assets/registerAssets/util.css";
import "./assets/registerAssets/main.css";
import "./assets/courseAssets/lecture.css";
import "./index.css";

// Templates
import RootTemplate from "./templates/RootTemplate";
import LectureTemplate from "./routes/courses/LectureTemplate";
import ContentTemplate, { contentLoader } from "./routes/courses/ContentTemplate";

// Administrators
import AdminDashboard from "./routes/management/AdminDashboard";

// Home Page
import Home from "./routes/home/Home";

// Account
import ResetForm from "./routes/account/ResetForm";
import Recover from "./routes/account/Recover";
import SignUp from "./routes/account/SignUp";
import Verify from "./routes/account/Verify";
import Submitted from "./routes/account/Submitted";
import LogIn from "./routes/account/LogIn";

// Profile
import Profile from "./routes/profile/Profile";
import JobPreferencesSurvey from "./routes/profile/surveys/JobPreferencesSurvey";
import PersonalitySurvey from "./routes/profile/surveys/PersonalitySurvey";

// Messages
import Chat from "./routes/chat/Chat";

// Test Center
import TestCenter from "./routes/test_center/TestCenter";
import { Behavioral } from "./routes/test_center/Behavioral";
import { Background } from "./routes/test_center/Background";
import Technical from "./routes/test_center/Technical";
import TechnicalHome from "./routes/test_center/TechnicalHome";

// Courses
import Courses from "./routes/courses/Courses";
import Lectures from "./routes/courses/lectures/Lectures";
import CourseOverview, { courseOverviewLoader } from "./routes/courses/CourseOverview";

// Course Recommender
import CourseRecommender from "./routes/course_recommender/CourseRecommender";

// Job Listings
import JobFinder from "./routes/job_finder/JobFinder";

// Recruitment Pipeline
import RecruitmentProgress from "./routes/recruitment_progress/RecruitmentProgress";

// Legal
import TermsOfUse from "./routes/legal/TermsOfUse";
import PrivacyPolicy from "./routes/legal/PrivacyPolicy";
import UseOfCookies from "./routes/legal/UseOfCookies";

// Website Helpers
import { Error } from "./routes/error/Error";
import NotFound from "./routes/error/NotFound";
import { VerificationRequiredRoute } from "./utils/VerificationRequiredRoute";
import SEOWrapper from "./components/SEOWrapper";

const router = createBrowserRouter([
    {
        path: "/",
        element: <RootTemplate />,
        children: [
            {
                path: "",
                element: (
                    <SEOWrapper
                        title="AI-Powered Career Development - AlgoLink"
                        description="AlgoLink offers AI-powered referrals, career development tools, and courses to help you advance in your tech or finance career."
                    >
                        {localStorage.getItem("access_token") &&
                        localStorage.getItem("is_email_verified") === "true" &&
                        localStorage.getItem("is_verified_by_algolink") ===
                            "true" ? (
                            <VerificationRequiredRoute
                                component={() => <Navigate to="/courses" />}
                            />
                        ) : (
                            <Home />
                        )}
                    </SEOWrapper>
                ),
            },
            {
                path: "chat",
                element: (
                    <SEOWrapper
                        title="Connect with Referrers - AlgoLink"
                        description="Talk with matched employees at big tech and quant firms to get advice on your resume, job search, and interviews."
                    >
                        <VerificationRequiredRoute component={Chat} />
                    </SEOWrapper>
                ),
            },
            {
                path: "management",
                element: (
                    <SEOWrapper
                        title="Admin Dashboard - AlgoLink"
                        description="Manage which applicants are let onto AlgoLink's platform to ensure that only the highest-quality candidates show up."
                    >
                        <VerificationRequiredRoute
                            component={AdminDashboard}
                            adminRequired={true}
                        />
                    </SEOWrapper>
                ),
            },
            {
                path: "account",
                children: [
                    {
                        path: "login",
                        element: (
                            <SEOWrapper
                                title="Access Your Account - AlgoLink"
                                description="Sign in to your AlgoLink account here."
                            >
                                <VerificationRequiredRoute
                                    component={LogIn}
                                />
                            </SEOWrapper>
                        ),
                    },
                    {
                        path: "recover",
                        element: (
                            <SEOWrapper
                                title="Recover Your Account - AlgoLink"
                                description="Recover your AlgoLink account by following the instructions sent to your email."
                            >
                                <Recover />
                            </SEOWrapper>
                        ),
                    },
                    {
                        path: "reset",
                        element: (
                            <SEOWrapper
                                title="Reset Your Password - AlgoLink"
                                description="Reset your AlgoLink password to secure your account and access your account"
                            >
                                <ResetForm />
                            </SEOWrapper>
                        ),
                    },
                    {
                        path: "signup",
                        element: (
                            <SEOWrapper
                                title="Kickstart Your Career - AlgoLink"
                                description="Create your AlgoLink account and begin your personalized, referral-based career development journey today."
                            >
                                <VerificationRequiredRoute
                                    component={SignUp}
                                />
                            </SEOWrapper>
                        ),
                    },
                    {
                        path: "verify",
                        element: (
                            <SEOWrapper
                                title="Verify Your Email - AlgoLink"
                                description="Verify your email address to complete your AlgoLink account registration and access our tools."
                            >
                                <Verify />
                            </SEOWrapper>
                        ),
                    },
                    {
                        path: "submitted",
                        element: (
                            <SEOWrapper
                                title="Application Submitted - AlgoLink"
                                description="Your AlgoLink verification is pending. Please wait for our team to review your application."
                            >
                                <VerificationRequiredRoute
                                    emailSuffices={true}
                                    component={Submitted}
                                />
                            </SEOWrapper>
                        ),
                    },
                ],
            },
            {
                path: "courses",
                children: [
                    {
                        path: "",
                        element: (
                            <SEOWrapper
                                title="Advance Your Skills - AlgoLink"
                                description="Explore our wide range of MIT-based courses across math, finance, and CS designed to enhance your skills and advance your career."
                            >
                                <VerificationRequiredRoute
                                    component={Courses}
                                />
                            </SEOWrapper>
                        ),
                    },
                    {
                        path: ":courseId",
                        element: (
                            <SEOWrapper
                                title="Course Overview - AlgoLink"
                                description="Get an overview of the course, including its syllabus, instructor, and learning objectives."
                            >
                                <VerificationRequiredRoute
                                    component={CourseOverview}
                                />
                            </SEOWrapper>
                        ),
                        loader: courseOverviewLoader,
                        shouldRevalidate: () => false,
                        errorElement: <Error />,
                        children: [
                            {
                                path: "lectures",
                                element: (
                                    <SEOWrapper
                                        title="Course Lectures - AlgoLink"
                                        description="Access and navigate through course lectures and learning materials."
                                    >
                                        <VerificationRequiredRoute
                                            component={LectureTemplate}
                                        />
                                    </SEOWrapper>
                                ),
                                shouldRevalidate: () => false,
                                errorElement: <Error />,
                                children: [
                                    {
                                        path: "",
                                        element: (
                                            <SEOWrapper
                                                title="Browse Lectures - AlgoLink"
                                                description="Browse and select the lectures you want to watch and learn from."
                                            >
                                                <VerificationRequiredRoute
                                                    component={Lectures}
                                                />
                                            </SEOWrapper>
                                        ),
                                    },
                                    {
                                        path: ":lessonId",
                                        element: (
                                            <SEOWrapper
                                                title="Lecture Content - AlgoLink"
                                                description="Access the lecture content, including videos, transcripts, and resources."
                                            >
                                                <VerificationRequiredRoute
                                                    component={ContentTemplate}
                                                />
                                            </SEOWrapper>
                                        ),
                                        loader: contentLoader,
                                        errorElement: <Error />,
                                        children: [
                                            {
                                                path: "content",
                                                element: (
                                                    <SEOWrapper
                                                        title="Lecture Video - AlgoLink"
                                                        description="Watch the lecture video and enhance your learning experience."
                                                    >
                                                        <div>Content</div>
                                                    </SEOWrapper>
                                                ),
                                            },
                                            {
                                                path: "resources",
                                                element: (
                                                    <SEOWrapper
                                                        title="Lecture Resources - AlgoLink"
                                                        description="Access additional resources related to the lecture for further learning and reference."
                                                    >
                                                        <div>Resources</div>
                                                    </SEOWrapper>
                                                ),
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                path: "course-recommender",
                children: [
                    {
                        path: "",
                        element: (
                            <SEOWrapper
                                title="Find Your Perfect Course - AlgoLink"
                                description="Get personalized course recommendations to show off to recruiters based on your skills, goals, and career aspirations."
                            >
                                <VerificationRequiredRoute
                                    component={CourseRecommender}
                                />
                            </SEOWrapper>
                        ),
                    },
                ],
            },
            {
                path: "job-preferences-survey",
                element: (
                    <SEOWrapper
                        title="Customize Your Career Path - AlgoLink"
                        description="Take our job preferences survey to help us tailor your referrals, career development journey, and course recommendations."
                    >
                        <VerificationRequiredRoute
                            component={JobPreferencesSurvey}
                        />
                    </SEOWrapper>
                ),
            },
            {
                path: "personality-survey",
                element: (
                    <SEOWrapper
                        title="Discover Your Professional Strengths - AlgoLink"
                        description="Complete our personality survey to help us gain insights into your professional strengths and ideal work environment."
                    >
                        <VerificationRequiredRoute
                            component={PersonalitySurvey}
                        />
                    </SEOWrapper>
                ),
            },
            {
                path: "job-finder",
                element: (
                    <SEOWrapper
                        title="Discover Your Next Tech Opportunity - AlgoLink"
                        description="Use our AI-powered job finder to discover and rate tech job opportunities tailored to your skills and preferences."
                    >
                        <VerificationRequiredRoute component={JobFinder} />
                    </SEOWrapper>
                ),
            },
            {
                path: "progress",
                element: (
                    <SEOWrapper
                        title="Track Your Career Growth - AlgoLink"
                        description="Monitor your career progress and application statuses with AlgoLink's comprehensive tracking system."
                    >
                        <VerificationRequiredRoute
                            component={RecruitmentProgress}
                        />
                    </SEOWrapper>
                ),
            },
            {
                path: "profile",
                element: (
                    <SEOWrapper
                        title="Manage Your Account - AlgoLink"
                        description="View and update your AlgoLink profile and manage your account settings here."
                    >
                        <VerificationRequiredRoute component={Profile} />
                    </SEOWrapper>
                ),
            },
            {
                path: "test-center",
                children: [
                    {
                        path: "home",
                        element: (
                            <SEOWrapper
                                title="Assess Your Skills - AlgoLink"
                                description="Take various tech assessments to gauge your skills and identify areas for improvement."
                            >
                                <VerificationRequiredRoute
                                    component={TestCenter}
                                />
                            </SEOWrapper>
                        ),
                    },
                    {
                        path: "technical-home",
                        element: (
                            <SEOWrapper
                                title="Technical Test Center - AlgoLink"
                                description="Assess your technical skills with our comprehensive technical test center."
                            >
                                <VerificationRequiredRoute
                                    component={TechnicalHome}
                                />
                            </SEOWrapper>
                        ),
                    },
                    {
                        path: "technical",
                        element: (
                            <SEOWrapper
                                title="Technical Assessment - AlgoLink"
                                description="Test your technical knowledge and skills with our interactive technical assessments."
                            >
                                <VerificationRequiredRoute
                                    component={Technical}
                                />
                            </SEOWrapper>
                        ),
                    },
                    {
                        path: "behavioral",
                        element: (
                            <SEOWrapper
                                title="Behavioral Assessment - AlgoLink"
                                description="Assess your behavioral skills and qualities with our behavioral test questions."
                            >
                                <VerificationRequiredRoute
                                    component={Behavioral}
                                />
                            </SEOWrapper>
                        ),
                    },
                    {
                        path: "background",
                        element: (
                            <SEOWrapper
                                title="Background Assessment - AlgoLink"
                                description="Test your background knowledge and understanding with our background assessment."
                            >
                                <VerificationRequiredRoute
                                    component={Background}
                                />
                            </SEOWrapper>
                        ),
                    },
                ],
            },
            {
                path: "terms-of-use",
                element: (
                    <SEOWrapper
                        title="Terms of Use - AlgoLink"
                        description="Read and understand AlgoLink's terms of use and service agreement."
                    >
                        <TermsOfUse />
                    </SEOWrapper>
                ),
            },
            {
                path: "privacy-policy",
                element: (
                    <SEOWrapper
                        title="Privacy Policy - AlgoLink"
                        description="Learn about how AlgoLink collects, uses, and protects your personal information."
                    >
                        <PrivacyPolicy />
                    </SEOWrapper>
                ),
            },
            {
                path: "use-of-cookies",
                element: (
                    <SEOWrapper
                        title="Use of Cookies - AlgoLink"
                        description="Understand how AlgoLink uses cookies to enhance your browsing experience and improve our services."
                    >
                        <UseOfCookies />
                    </SEOWrapper>
                ),
            },
            {
                path: "*",
                element: (
                    <SEOWrapper
                        title="Page Not Found - AlgoLink"
                        description="The page you're looking for doesn't exist. Navigate back to AlgoLink's homepage or explore our courses."
                    >
                        <NotFound />
                    </SEOWrapper>
                ),
            },
        ],
    },
]);

function App(): JSX.Element {
    return (
        <div className="App" style={{ backgroundColor: "#ffffff" }}>
            <RouterProvider router={router} />
        </div>
    );
}

export default App;