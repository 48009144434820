// src/routes/profile/CareerProfile.tsx

import React, { useState, useEffect } from "react";
import CareerCard from "./career/CareerCard";
import { PDFViewer } from "./career/DocumentViewer";
import EducationComponent, { CreateEdu } from "./career/Education";
import ExperienceComponent, { CreateExp } from "./career/Experience";
import { ExternalCourse, CreateCourse } from "./career/ExternalCourses";
import ResumeComponent, { CreateRes } from "./career/Resume";
import TranscriptComponent, { CreateTrans } from "./career/Transcript";
import axiosInterceptor from "../../utils/axiosInterceptor";
import { Skill, EducationItem, ExperienceInfo, Course, ResumeItem, TranscriptItem } from "./types";
import { Skills, CreateSkill } from "./career/Skills";

interface UserData {
  u_data: any; // Replace with a proper type if known
  ed_data: EducationItem[];
  course_data: Course[];
  exp_data: ExperienceInfo[];
  skill_data: Skill[];
  resume: ResumeItem[];
  transcript: TranscriptItem[];
}

const BLANK_USER: any = {};
const BLANK_SKILLS: Skill[] = [];
const BLANK_EDUCATION: EducationItem[] = [];
const BLANK_COURSES: Course[] = [];
const BLANK_EXPERIENCE: ExperienceInfo[] = [];
const BLANK_RESUME: ResumeItem[] = [];
const BLANK_TRANSCRIPT: TranscriptItem[] = [];

const CareerProfile: React.FC = () => {
  const [userData, setUserData] = useState<UserData>({
    u_data: BLANK_USER,
    ed_data: BLANK_EDUCATION,
    course_data: BLANK_COURSES,
    exp_data: BLANK_EXPERIENCE,
    skill_data: BLANK_SKILLS,
    resume: BLANK_RESUME,
    transcript: BLANK_TRANSCRIPT,
  });

  const [skills, setSkills] = useState<Skill[]>(BLANK_SKILLS);
  const [education, setEducation] = useState<EducationItem[]>(BLANK_EDUCATION);
  const [courses, setCourses] = useState<Course[]>(BLANK_COURSES);
  const [experiences, setExperiences] = useState<ExperienceInfo[]>(BLANK_EXPERIENCE);
  const [resume, setResume] = useState<ResumeItem[]>(BLANK_RESUME);
  const [transcript, setTranscript] = useState<TranscriptItem[]>(BLANK_TRANSCRIPT);

  const userId = localStorage.getItem("user_id") || "";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInterceptor.get<UserData>(`/getuser/${userId}/`);
        setUserData(response.data);
        setEducation(response.data.ed_data);
        setCourses(response.data.course_data);
        setExperiences(response.data.exp_data);
        setSkills(response.data.skill_data);
        setResume(response.data.resume || []);
        setTranscript(response.data.transcript || []);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    if (userId) {
      fetchData();
    }
  }, [userId]);

  // Prepare userEds for CreateCourse
  const userEds = education.map(({ id, school, degree }) => ({ id, school, degree }));

  return (
    <div className="flex flex-col gap-y-3 w-full">
      <CareerCard title="Experiences" showEditButton={false}>
        <ExperienceComponent expList={experiences} />
        <CreateExp expList={experiences} setExperiences={setExperiences} />
      </CareerCard>
      
      <CareerCard title="Skills" showAddButton={false} modalTitle="Manage Skills">
        <Skills skills={skills} />
        <CreateSkill skills={skills} setSkills={setSkills} />
      </CareerCard>
      
      <CareerCard title="Education" showEditButton={false}>
        <EducationComponent eduList={education} />
        <CreateEdu eduList={education} setEducation={setEducation} />
      </CareerCard>
      
      <CareerCard title="External Courses" showEditButton={false}>
        <ExternalCourse courseList={courses} />
        <CreateCourse 
          courseList={courses} 
          setCourse={setCourses} 
          userEds={userEds} 
        />
      </CareerCard>
      
      <CareerCard
        title="Resume/CV"
        showAddButton={false}
        showEditAsModal={false}
      >
        <PDFViewer userId={userId} documentType="Resume" />
        <div>
          <ResumeComponent resume={resume} />
          <CreateRes resume={resume} userId={userId} />
        </div>
      </CareerCard>

      <CareerCard
        title="Transcript"
        showAddButton={false}
        showEditAsModal={false}
      >
        <PDFViewer userId={userId} documentType="Transcript" />
        <div>
          <TranscriptComponent transcript={transcript} />
          <CreateTrans transcript={transcript} userId={userId} />
        </div>
      </CareerCard>
    </div>
  );
};

export default CareerProfile;
