import React, { useState } from "react";
import { Button } from "react-bootstrap";
import axiosInterceptor from "../../utils/axiosInterceptor";
import StarRating from "./StarRating";
import Job from "./types";

interface JobDetailsProps {
    job: Job | null;
}


const SelectedJobDetails: React.FC<JobDetailsProps> = ({ job }) => {
    const [currentRating, setCurrentRating] = useState<number>(0);
    const [showHoverCard, setShowHoverCard] = useState<boolean>(false);
    if (!job) return null;
    const formatSalary = (lower: number, upper: number): string => {
        const formatNumber = (num: number): string => `$${Math.round(num / 1000)}k`;
        return `${formatNumber(lower)} - ${formatNumber(upper)}`;
    };
    const formatDate = (dateString: string): string => {
        return new Date(dateString).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
        });
    };
    const handleRating = (rate: number) => {
        setCurrentRating(rate);

        const ratingData = {
            user_id: localStorage.getItem("user_id"),
            job_id: job.id,
            rating: rate,
            rating_type: "user_rating_job",
        };
        axiosInterceptor
            .post("/create_or_update_rating/", ratingData)
            .then((response) => {
                console.log("Rating submitted", response.data);
                window.location.reload();
            })
            .catch((error) => {
                console.error("Error submitting rating", error);
            });
    };
    const ReplaceNewlines = (text: string): JSX.Element => {
        return (
            <div>
                {text.split("\n").map((line, index) => (
                    <React.Fragment key={index}>
                        {line}
                        <br />
                    </React.Fragment>
                ))}
            </div>
        );
    };
    return (
        <div className="flex flex-col gap-y-2">
            <div className="bg-white shadow-lg rounded-lg overflow-hidden w-full mx-auto grid grid-cols-6 p-6 items-center">
                <div className="col-span-5">
                    <div className="flex flex-row gap-x-4 items-center bg-white p-2 rounded-lg">
                        <div className="!size-16 rounded-lg bg-[#D9D9D9]" />
                        <div className="flex flex-col gap-y-0 h-min w-[75%]">
                            <p className="font-semibold text-black text-xl">
                                {job.company_details.company_name}
                            </p>
                            <p className="text-black font-light">
                                {job.company_details.industry}
                            </p>
                        </div>
                    </div>
                    <div className="flex justify-between items-start mb-3 mt-1">
                        <div>
                            <h2 className="text-3xl font-bold text-gray-800">
                                {job.title}
                            </h2>
                            <p className="text-gray-600 text-sm mt-1">
                                Posted{" "}
                                {new Date(job.timestamp).toLocaleDateString()}
                            </p>
                        </div>
                    </div>
                    <div className="space-y-4">
                        <div className="flex items-center space-x-2 text-black">
                            <svg
                                className="w-5 h-5"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                            </svg>
                            <span>
                                {formatSalary(
                                    job.compensationLowerBound,
                                    job.compensationUpperBound
                                )}
                                /yr<span className="mx-1.5">&bull;</span>
                            </span>
                        </div>
                        <div className="flex items-center space-x-2 text-black">
                            <svg
                                className="w-5 h-5"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                />
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                            </svg>
                            <span>{job.city} &bull; On-site</span>
                        </div>
                        <div className="flex items-center space-x-2 text-black">
                            <svg
                                className="w-5 h-5"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                                />
                            </svg>
                            <span>Starts: {formatDate(job.jobStartDate)}</span>
                        </div>
                        {job.eligible_for_visa_sponsorship && (
                            <div className="flex items-center space-x-2 text-black">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-5 h-5"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                    />
                                    <path d="M3 4m0 3a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v10a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3z" />
                                    <path d="M9 10m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                                    <path d="M15 8l2 0" />
                                    <path d="M15 12l2 0" />
                                    <path d="M7 16l10 0" />
                                </svg>
                                <span>Eligible for visa sponsorship</span>
                            </div>
                        )}
                        {!job.eligible_for_visa_sponsorship && (
                            <div className="flex items-center space-x-2 text-black">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-5 h-5"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                    />
                                    <path d="M8 4h10a3 3 0 0 1 3 3v10m-1.437 2.561c-.455 .279 -.99 .439 -1.563 .439h-12a3 3 0 0 1 -3 -3v-10c0 -1.083 .573 -2.031 1.433 -2.559" />
                                    <path d="M8.175 8.178a2 2 0 1 0 2.646 2.65" />
                                    <path d="M15 8h2" />
                                    <path d="M16 12h1" />
                                    <path d="M7 16h9" />
                                    <path d="M3 3l18 18" />
                                </svg>
                                <span>Not eligible for visa sponsorship</span>
                            </div>
                        )}
                    </div>
                    <div className="items-start flex flex-col gap-y-2 mt-2 mx-auto">
                        <StarRating
                            activeStars={currentRating}
                            totalStars={5}
                            starStyle={{ width: "40px", height: "40px" }}
                            activeColor="#1B4E99"
                            inactiveColor="#D9D9D9"
                            onClick={handleRating}
                        />
                        <div className="mb-auto mt-0 self-start ml-1 relative">
                            <p
                                className="inline-block ml-1 cursor-pointer italic underline text-[#1B4E99]"
                                onMouseEnter={() => setShowHoverCard(true)}
                                onMouseLeave={() => setShowHoverCard(false)}
                                onClick={() => setShowHoverCard(!showHoverCard)} 
                            >
                                What is this?
                            </p>
                            {showHoverCard && (
                                <div className="absolute -top-8 z-10 text-sm w-64 left-[7rem] bg-white p-2 shadow border rounded-xl text-wrap select-none font-normal">
                                    Your personal information won’t be shown to
                                    your employers or any applications
                                    submitted.
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white shadow-lg rounded-lg overflow-hidden w-full mx-auto">
                <div className="p-6">
                    <div className="mt-6 space-y-4">
                        <div>
                            <h3 className="text-lg font-semibold text-gray-800">
                                Job Description
                            </h3>
                            <p className="mt-2 text-gray-600">
                                {ReplaceNewlines(job.description)}
                            </p>
                        </div>

                        <div>
                            <h3 className="text-lg font-semibold text-gray-800">
                                Interview Process
                            </h3>
                            <div className="mt-2 flex flex-wrap gap-2">
                                {job.rounds.map((round, index) => (
                                    <span
                                        key={round.id}
                                        className="px-2 py-1 text-sm font-medium text-gray-700 bg-gray-100 rounded-full"
                                    >
                                        {index + 1}. {round.name}
                                    </span>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white shadow-lg rounded-lg overflow-hidden w-full mx-auto">
                <div className="p-6">
                    <div>
                        <h3 className="text-lg font-semibold text-gray-800">
                            Benefits
                        </h3>
                        <p className="mt-2 text-gray-600">{job.benefits}</p>
                    </div>
                </div>
            </div>
            <div className="bg-white shadow-lg rounded-lg overflow-hidden w-full mx-auto">
                <div className="p-6 flex flex-col gap-y-2">
                    <div className="font-semibold mb-2">About the Company</div>
                    <div className="flex flex-row gap-x-4 items-center rounded-lg">
                        <div className="!size-16 rounded-lg bg-[#D9D9D9]" />
                        <div className="flex flex-col gap-y-0 h-min w-[75%]">
                            <p className="font-semibold text-black text-xl">
                                {job.company_details.company_name}
                            </p>
                            <p className="text-black font-light">
                                {job.company_details.industry}
                            </p>
                        </div>
                        <Button variant="outline-primary rounded-full text-[#1B4E99] border-[#1B4E99] hover:border-[#1B4E99] hover:bg-[#1B4E99]">
                            Follow
                        </Button>
                    </div>
                    <div className="flex flex-row gap-x-2">
                        <div className="bg-gray-300 rounded px-2 py-1 text-xs">
                            {job.company_details.company_size} employees
                        </div>
                        <div className="bg-gray-300 rounded px-2 py-1 text-xs">
                            <div className="flex items-center space-x-1.5">
                                <svg
                                    className="w-4 h-4"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                    />
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                    />
                                </svg>
                                <span>{job.city}</span>
                            </div>
                        </div>
                    </div>

                    <p className="mt-3 text-black">
                        {job.company_details.company_description}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default SelectedJobDetails;