// src/components/career/Resume.tsx

import React from "react";
import { ResumeProps, CreateResProps } from "../types";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import { Upload } from "../../../components/Upload";

const ResumeComponent: React.FC<ResumeProps> = ({ resume }) => {
  return (
    <div>
      {resume.map((res, index) => (
        <a
          key={index}
          className="resume-link one-line"
          href="https://google.com" // Replace with actual resume URL
          target="_blank"
          rel="noopener noreferrer"
        >
          {res.title}
        </a>
      ))}
    </div>
  );
};

const CreateRes: React.FC<CreateResProps> = ({ resume, userId }) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData();
    resume.forEach((res, index) => {
      formData.append(`resume_${index}`, res.description); // Adjust as needed
    });
    // Add additional form submission logic here
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Stack gap={2}>
        <Upload
          userId={userId}
          rootDirectory="resumes"
          uploadEndpoint={`file_upload/`}
        />
        {/* Add a submit button if needed */}
        {/* <button type="submit">Submit</button> */}
      </Stack>
    </Form>
  );
};

export default ResumeComponent;
export { CreateRes };
