// src/components/ScreenSpinner.tsx
import React from "react";

interface ScreenSpinnerProps {
    extraClasses?: string;
}

const ScreenSpinner: React.FC<ScreenSpinnerProps> = ({ extraClasses = "" }) => {
    // Safely handle document access (in case of SSR)
    const switchElement = typeof document !== "undefined" ? document.getElementById("switchBetween") : null;
    const switchHeight = switchElement?.clientHeight ?? 0;

    return (
        <div
            className={"flex justify-center w-full " + extraClasses}
            style={{
                height: `calc(100vh - ${switchHeight}px - 22px - 32px)`,
            }}
        >
            <div className="mt-auto mb-auto lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default ScreenSpinner;
