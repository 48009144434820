import React, {
    useState,
    useEffect,
    useMemo,
    ChangeEvent,
    FormEvent,
    ReactElement,
} from "react";
import { Modal, Button, Dropdown, ListGroup, Container } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import ReadMore from "./ReadMore";
import axiosInterceptor from "../../utils/axiosInterceptor";
import ScreenSpinner from "../../components/ScreenSpinner";

interface JobDetails {
    company_name: string;
    company_description: string;
    industry: string;
}

interface JobRound {
    name: string;
}

interface Job {
    id: number;
    title: string;
    city: string;
    compensationLowerBound: number;
    compensationUpperBound: number;
    company_details: JobDetails;
    description: string;
    benefits: string;
    rounds: JobRound[];
    userRound?: string;
}

interface SearchParams {
    keyword: string;
}

const RecruitmentProgress: React.FC = () => {
    const user_id = localStorage.getItem("user_id") || "";
    const navigate = useNavigate();
    const location = useLocation();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [loadedListings, setLoadedListings] = useState<Job[]>([]);
    const [originalListings, setOriginalListings] = useState<Job[]>([]);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [jobToReject, setJobToReject] = useState<number | null>(null);
    const [showJobProfileModal, setShowJobProfileModal] = useState(false);
    const [selectedJobForProfile, setSelectedJobForProfile] = useState<Job | null>(null);
    const [activeTab, setActiveTab] = useState<"Active" | "Archived">("Active");
    const [dateFilter, setDateFilter] = useState<string>("");
    const [statusFilter, setStatusFilter] = useState<string>("");
    const [locationFilter, setLocationFilter] = useState<string>("");
    const [showJobDescriptionModal, setShowJobDescriptionModal] = useState(false);
    const [selectedJob, setSelectedJob] = useState<Job | null>(null);
    const [searchParams, setSearchParams] = useState<SearchParams>({ keyword: "" });
    const [statusOptions, setStatusOptions] = useState<string[]>([
        "Application Submitted",
        "Awaiting Review",
        "Application Reviewed",
        "Application Accepted",
        "Interview",
        "Rejected by Candidate",
        "Mutual Rejection",
        "Rejected",
        "User Interest",
    ]);
    const [locationOptions, setLocationOptions] = useState<string[]>([]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const paramsFromUrl: SearchParams = {
            keyword: queryParams.get("keyword") || "",
        };
        setSearchParams(paramsFromUrl);
    }, [location]);

    useEffect(() => {
        axiosInterceptor
            .get<Job[]>("/get_all_active_jobs/")
            .then((response) => {
                axiosInterceptor
                    .get(`/get_user_pipeline_progress/${user_id}/`)
                    .then((pipelineResponse) => {
                        let updatedListings = response.data.map((job) => {
                            const pipelineProgress = pipelineResponse.data.find(
                                (progress: { job_id: number; status: string }) =>
                                    progress.job_id === job.id
                            );
                            return {
                                ...job,
                                userRound: pipelineProgress
                                    ? pipelineProgress.status
                                    : "No Progress",
                            };
                        });
                        updatedListings = updatedListings.filter(
                            (job) => job.userRound !== "No Progress"
                        );
                        setOriginalListings(updatedListings);
                        setLoadedListings(updatedListings);
                        updateFilterOptions(updatedListings);
                    })
                    .catch((pipelineError) => {
                        console.error("Error fetching pipeline progress:", pipelineError);
                    })
                    .finally(() => {
                        setDataLoaded(true);
                    });
            })
            .catch((error) => {
                console.error("Error fetching jobs:", error);
            });
    }, [user_id]);

    const promptNotInterested = (jobId: number) => {
        setJobToReject(jobId);
        setShowConfirmationModal(true);
    };

    const confirmNotInterested = () => {
        if (jobToReject !== null) {
            const updateData = {
                user_id: user_id,
                new_status: "Rejected by Candidate",
            };
            axiosInterceptor
                .post(`/update_pipeline_status/${jobToReject}/`, updateData)
                .then(() => {
                    const updatedListings = loadedListings.filter(
                        (job) => job.id !== jobToReject
                    );
                    setLoadedListings(updatedListings);
                })
                .catch((error) => {
                    console.error("Error updating pipeline progress:", error);
                });
        }
        setShowConfirmationModal(false);
        setJobToReject(null);
    };

    const getStatusColor = (status: string) => {
        switch (status) {
            case "Application Rejected":
                return "bg-red-500 text-white";
            case "Application Accepted":
                return "bg-green-500 text-white";
            default:
                return "bg-[#004DE5] text-white";
        }
    };

    const renderProgressBar = (job: Job) => {
        let allStages = [
            "User Interest",
            "Awaiting Review",
            ...job.rounds
                .map((round) => round.name)
                .filter((name) => !name.toLowerCase().includes("rejected")),
            "Accepted",
        ];
        if (
            job.rounds.some((round) =>
                round.name.toLowerCase().includes("rejected")
            )
        ) {
            allStages.push("Rejected");
            allStages = allStages.filter((stage) => stage !== "Accepted");
        }
        if (job.userRound === "User Interest") {
            job.userRound = "Awaiting Review";
        }

        const userRound = job.userRound ?? "";

        return (
            <div className="grid grid-flow-col items-center w-full mt-2 overflow-x-auto">
                {allStages.map((stage, index) => (
                    <div key={index} className="flex flex-col items-center flex-grow">
                        <div className="relative">
                            {index > 0 && (
                                <div
                                    className={`absolute top-1/2 right-full w-11 h-0.5 -translate-y-1/2
                                        ${
                                            allStages.indexOf(userRound) + 1 > index
                                                ? getStatusColor(stage)
                                                : "bg-gray-200"
                                        }`}
                                ></div>
                            )}
                            <div
                                className={`size-7 rounded-full flex items-center justify-center text-sm
                                    ${
                                        userRound === stage || allStages.indexOf(userRound) + 1 > index
                                            ? getStatusColor(stage)
                                            : "text-[#242E39] border !border-opacity-50 !border-black"
                                    }`}
                            >
                                {index + 1 < 10 ? `0${index + 1}` : index + 1}
                            </div>
                            {index < allStages.length - 1 && (
                                <div
                                    className={`absolute top-1/2 left-full w-11 h-0.5 -translate-y-1/2
                                        ${
                                            allStages.indexOf(userRound) > index
                                                ? getStatusColor(stage)
                                                : "bg-gray-200"
                                        }`}
                                ></div>
                            )}
                        </div>
                        <p
                            className="text-gray-600 text-center whitespace-nowrap mt-1 w-28"
                            style={{ fontSize: "0.65rem" }}
                        >
                            {stage}
                        </p>
                    </div>
                ))}
            </div>
        );
    };

    const handleShowDescription = (job: Job) => {
        setSelectedJob(job);
        setShowJobDescriptionModal(true);
    };

    const renderJobListings = (): ReactElement[] => {
        return filteredListings.map((job, index) => (
            <ListGroup.Item key={index} className="job-item p-2 rounded-lg">
                <div className="flex items-start">
                    <div className="size-16 bg-[#D9D9D9] rounded-lg mr-4"></div>
                    <div className="flex-grow flex-row">
                        <div className="flex justify-between items-start">
                            <div>
                                <h3 className="text-base font-semibold">
                                    {job.title}
                                </h3>
                                <p className="text-sm font-medium text-black">
                                    {job.company_details.company_name}
                                </p>
                                <p className="text-xs text-[#555555] font-base">
                                    $
                                    {Math.round(
                                        job.compensationLowerBound / 1000
                                    )}
                                    -
                                    {Math.round(
                                        job.compensationUpperBound / 1000
                                    )}
                                    k/yr • {job.city}
                                </p>
                            </div>
                        </div>
                        <div className=" flex flex-row flex-reverse ml-auto mr-0">
                            {renderProgressBar(job)}
                        </div>
                    </div>
                    <Dropdown>
                        <Dropdown.Toggle
                            size="sm"
                            variant="ghost"
                            className="flex items-center"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="size-5 inline mr-2"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM18.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
                                />
                            </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleShowDescription(job)}>
                                View Description
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => promptNotInterested(job.id)}
                                className="text-danger"
                            >
                                Not Interested
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </ListGroup.Item>
        ));
    };

    const renderJobProfileModal = () => {
        if (!selectedJobForProfile) return null;
        console.log(selectedJobForProfile);
        return (
            <Modal
                show={showJobProfileModal}
                onHide={() => setShowJobProfileModal(false)}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {selectedJobForProfile.title} at{" "}
                        {selectedJobForProfile.company_details.company_name} Description
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="job-profile-container">
                        <div className="job-app-container">
                            <div className="job-card">
                                <p className="job-title">
                                    Job Title: {selectedJobForProfile.title}
                                </p>
                                <p className="job-company">
                                    Company: {selectedJobForProfile.company_details.company_name}
                                </p>
                                <p className="job-details">
                                    <strong>Industry:</strong> {selectedJobForProfile.company_details.industry}
                                </p>
                                <p className="job-details">
                                    <strong>City:</strong> {selectedJobForProfile.city}
                                </p>
                                <p className="job-details">
                                    <strong>Salary Range:</strong> $
                                    {selectedJobForProfile.compensationLowerBound.toLocaleString()}{" "}
                                    - $
                                    {selectedJobForProfile.compensationUpperBound.toLocaleString()}
                                </p>
                                <p className="job-details">
                                    <strong>Benefits:</strong> {selectedJobForProfile.benefits}
                                </p>
                                <p className="job-details">
                                    <strong>Company Description:</strong>
                                    <ReadMore maxLength={200}>
                                        {selectedJobForProfile.company_details.company_description}
                                    </ReadMore>
                                </p>
                                <p className="job-details">
                                    <strong>Job Description:</strong>
                                    <ReadMore maxLength={200}>
                                        {selectedJobForProfile.description}
                                    </ReadMore>
                                </p>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };

    const renderJobDescriptionModal = () => {
        if (!selectedJob) return null;
        return (
            <Modal
                show={showJobDescriptionModal}
                onHide={() => setShowJobDescriptionModal(false)}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {selectedJob.title} at {selectedJob.company_details.company_name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="prose">
                    <p>
                        <strong>Location:</strong> {selectedJob.city}
                    </p>
                    <p>
                        <strong>Salary Range:</strong> $
                        {selectedJob.compensationLowerBound.toLocaleString()} -
                        ${selectedJob.compensationUpperBound.toLocaleString()}
                        /yr
                    </p>
                    <p>
                        <strong>Benefits:</strong> {selectedJob.benefits}
                    </p>
                    <p>
                        <strong className="underline">Company Description</strong>
                    </p>
                    <ReadMore maxLength={200}>
                        {selectedJob.company_details.company_description}
                    </ReadMore>
                    <p>
                        <strong className="underline">Job Description</strong>
                    </p>
                    <ReadMore maxLength={200}>
                        {selectedJob.description}
                    </ReadMore>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setShowJobDescriptionModal(false)}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    const goToJobMatcher = (): void => {
        navigate("/job-finder");
    };

    const handleDateFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setDateFilter(e.target.value);
        filterJobs();
    };

    const handleStatusFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newStatus = e.target.value;
        setStatusFilter(newStatus);
        filterJobs(newStatus, locationFilter);
    };

    const handleLocationFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newLocation = e.target.value;
        setLocationFilter(newLocation);
        filterJobs(statusFilter, newLocation);
    };

    const filterJobs = (status?: string, location?: string) => {
        let filteredJobs = [...originalListings];

        if (status) {
            filteredJobs = filteredJobs.filter((job) => job.userRound === status);
        }

        if (location) {
            filteredJobs = filteredJobs.filter((job) => job.city === location);
        }

        setLoadedListings(filteredJobs);
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchParams({
            ...searchParams,
            [e.target.name]: e.target.value,
        });
    };

    const handleSearch = (e: FormEvent) => {
        e.preventDefault();
        updateQueryParams();
    };

    const updateQueryParams = () => {
        const queryParams = new URLSearchParams();
        Object.entries(searchParams).forEach(([key, value]) => {
            if (value) queryParams.append(key, value);
        });
        navigate(`${location.pathname}?${queryParams.toString()}`, {
            replace: true,
        });
    };

    const filteredListings = useMemo(() => {
        return loadedListings.filter((job) =>
            job.title.toLowerCase().includes(searchParams.keyword.toLowerCase())
        );
    }, [loadedListings, searchParams.keyword]);

    const updateFilterOptions = (jobs: Job[]) => {
        const customStatuses = [...new Set(jobs.map((job) => job.userRound || ""))];
        const allStatuses = [...new Set([...statusOptions, ...customStatuses])];
        setStatusOptions(allStatuses);

        const locations = [...new Set(jobs.map((job) => job.city))];
        setLocationOptions(locations);
    };

    return !dataLoaded ? (
        <ScreenSpinner />
    ) : (
        <Container fluid className="recruitment-progress">
            <h1 className="text-3xl font-medium text-left mb-4">Recruitment Progress</h1>
            <div className="flex flex-row my-4">
                <button
                    className={`text-lg ${
                        activeTab === "Active" ? "font-medium text-black" : "text-[#555]"
                    }`}
                    onClick={() => setActiveTab("Active")}
                >
                    Active
                </button>
                <button
                    className={`ml-8 text-lg ${
                        activeTab === "Archived" ? "font-medium text-black" : "text-[#555]"
                    }`}
                    onClick={() => setActiveTab("Archived")}
                >
                    Archived
                </button>
            </div>
            <div className="flex flex-col md:flex-row my-4 flex-wrap justify-between gap-2">
                <div className="flex flex-row space-x-2">
                    <div className="relative">
                        <input
                            type="text"
                            placeholder="Job title, keyword, or company"
                            className="w-[20.5rem] pl-12 pr-8 py-2 rounded"
                            name="keyword"
                            value={searchParams.keyword}
                            onChange={handleInputChange}
                        />
                        <i className="bi bi-search absolute top-1/2 transform -translate-y-1/2 left-4 text-black"></i>
                    </div>
                    <Button
                        variant="outline-primary border-2 rounded-full"
                        onClick={handleSearch}
                    >
                        Search
                    </Button>
                </div>
                <div className="flex space-x-2">
                    <select
                        name="dateApplied"
                        value={dateFilter}
                        onChange={handleDateFilterChange}
                        className="border-2 font-medium !pl-4 border-black py-1.5 rounded-full bg-transparent padded-select-arrow cursor-not-allowed opacity-50"
                        disabled
                    >
                        <option value="">Date Applied</option>
                        <option value="1">Past 24 hours</option>
                        <option value="7">Past week</option>
                        <option value="30">Past month</option>
                        <option value="90">Past 3 months</option>
                    </select>
                    <select
                        name="status"
                        value={statusFilter}
                        onChange={handleStatusFilterChange}
                        className="border-2 font-medium !pl-4 border-black py-1.5 rounded-full bg-transparent padded-select-arrow"
                    >
                        <option value="">Status</option>
                        {statusOptions.map((status, index) => (
                            <option key={index} value={status}>
                                {status}
                            </option>
                        ))}
                    </select>
                    <select
                        name="location"
                        value={locationFilter}
                        onChange={handleLocationFilterChange}
                        className="border-2 font-medium !pl-4 border-black py-1.5 rounded-full bg-transparent padded-select-arrow"
                    >
                        <option value="">Location</option>
                        {locationOptions.map((location, index) => (
                            <option key={index} value={location}>
                                {location}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <p className="text-sm text-gray-600 -mt-2 mb-3">
                {filteredListings.length} results
            </p>
            <ListGroup className="job-list mt-2 rounded-lg overflow-hidden flex flex-col gap-1.5">
                {filteredListings.length > 0 ? (
                    renderJobListings()
                ) : (
                    <div className="flex flex-col items-center justify-center gap-2">
                        <Button onClick={goToJobMatcher} variant="primary">
                            Go to Job Finder
                        </Button>
                        <p>
                            No recruitment information found, please broaden
                            your search parameters or{" "}
                            <a href="/job-finder" className="text-blue-500">
                                go to the Job Finder
                            </a>
                            .
                        </p>
                    </div>
                )}
            </ListGroup>
            <Modal
                show={showConfirmationModal}
                onHide={() => setShowConfirmationModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Action</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you are not interested in this job?
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setShowConfirmationModal(false)}
                    >
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={confirmNotInterested}>
                        Not Interested
                    </Button>
                </Modal.Footer>
            </Modal>
            {renderJobProfileModal()}
            {renderJobDescriptionModal()}
        </Container>
    );
};

export default RecruitmentProgress;
