import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

export default function Lectures(): JSX.Element {
    console.log("in Lectures");

    const navigate = useNavigate();
    const location = useLocation();

    const goToNextLecture = (): void => {
        console.log("trying to go to next lecture");

        const urlPattern = /\/(\d+)(\/\w+)?$/;
        const match = location.pathname.match(urlPattern);

        if (match) {
            const currentLectureNumber = parseInt(match[1], 10);
            const additionalPathSegment = match[2]; 
            const nextLectureNumber = currentLectureNumber + 1;

            let newPath: string;
            if (additionalPathSegment) {
                newPath = location.pathname.replace(
                    urlPattern,
                    `/${nextLectureNumber}${additionalPathSegment}`
                );
            } else {
                newPath = location.pathname.replace(urlPattern, `/${nextLectureNumber}`);
            }

            navigate(newPath);
        } else {
            console.error("The current URL doesn't match the expected pattern.");
        }
    };

    return (
        <div>
            <button onClick={goToNextLecture}>Go to Next Lecture</button>
        </div>
    );
}