import React from "react";
import Logo from "../../assets/img/home/logo.png";

const Footer: React.FC = () => {
    return (
        <div
            className="px-6 py-4 mt-20"
            style={{
                backgroundColor: "#ffffff",
            }}
        >
            <div>
                <img
                    src={Logo}
                    alt="AlgoLink Logo"
                    className="d-inline-block align-top me-1 h-10"
                />
            </div>
        </div>
    );
};

export default Footer;
