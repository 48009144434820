import { API_BASE_URL } from "../../constants";
import axiosInterceptor from "../../utils/axiosInterceptor";

export async function getFilesFromDirectory(directoryPath: string): Promise<string[]> {
    try {
        const response = await axiosInterceptor.get(`/list_files/${directoryPath}`);
        if (response.status !== 200) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.data.file_urls;
    } catch (error: any) {
        console.error(`Error fetching files from directory: ${error.message}`);
        return [];
    }
}

export async function fetchPngFromUrl(url: string): Promise<Blob> {
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.blob();
    } catch (error: any) {
        console.error("Error fetching PNG:", error.message);
        throw error;
    }
}

export const fetchJsonData = async (url: string): Promise<any> => {
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    } catch (error: any) {
        console.error(`Error fetching JSON data: ${error.message}`);
        throw error;
    }
};

export const getJsonCourseData = async (): Promise<any> => {
    try {
        const response = await axiosInterceptor.get("/api/serve_course_jsons/");
        return response.data;
    } catch (error: any) {
        console.error("Error fetching JSON files:", error.message);
        throw error;
    }
};

export const getJsonUrl = async (): Promise<string[]> => {
    try {
        const listResponse = await axiosInterceptor.get(`/list_files/generated`);
        if (listResponse.status !== 200) {
            throw new Error(`HTTP error! Status: ${listResponse.status}`);
        }
        const jsonResponse = listResponse.data;
        const fileList = jsonResponse.file_urls;
        const filePaths = fileList.map((file: string) =>
            `${API_BASE_URL}/${file.replace("public/static/", "serve_json")}`
        );
        const serveJsonResponse = await axiosInterceptor.post(`/serve_json/`, {
            file_paths: filePaths,
        });
        if (serveJsonResponse.status !== 200) {
            throw new Error(`HTTP error! Status: ${serveJsonResponse.status}`);
        }
        return serveJsonResponse.data.map((item: any) => item.presigned_url);
    } catch (error: any) {
        console.error("Error fetching JSON URLs:", error.message);
        throw error;
    }
};

function sleep(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export const fetchDataFromUrls = async (jsonUrls: string[]): Promise<any[]> => {
    const jsonDataArray: any[] = [];
    console.log("fetchDataFromUrls", jsonUrls);
    for (const url of jsonUrls) {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const jsonData = await response.json();
            jsonDataArray.push(jsonData);
            await sleep(1000);
        } catch (error: any) {
            console.error("Error fetching JSON data:", error.message);
            throw error;
        }
    }
    return jsonDataArray;
};

export async function fetchDataFromUrl(url: string, fileType: string): Promise<any> {
    try {
        const dataUrl = `/serve_${fileType}/${url}`;
        console.log(dataUrl);
        const response = await axiosInterceptor.get(dataUrl);
        if (response.status !== 200) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.data;
    } catch (error: any) {
        console.error("Error fetching data:", error.message);
        throw error;
    }
}

export async function getJsonFromUrl(apiUrl: string): Promise<any> {
    try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    } catch (error: any) {
        console.error("Error fetching JSON:", error.message);
        throw error;
    }
}

export function findDirectoryForLesson(
    lessonId: number,
    data: { filesInNonEmptyDirectories: string[][] }
): { count: number; foundDirectory: string | null } {
    let totalItemsSeen = 0;
    let foundDirectory: string | null = null;
    let count = 1;

    for (const fileList of data.filesInNonEmptyDirectories) {
        if (lessonId >= totalItemsSeen && lessonId < totalItemsSeen + fileList.length) {
            const indexInList = lessonId - totalItemsSeen;
            fileList.sort((a, b) => {
                const numberA = parseInt(a.match(/(\d+)/)?.[0] || "0", 10);
                const numberB = parseInt(b.match(/(\d+)/)?.[0] || "0", 10);
                return numberA - numberB;
            });
            foundDirectory = fileList[indexInList];
            break;
        }
        totalItemsSeen += fileList.length;
        count += 1;
    }

    return { count, foundDirectory };
}