import React, { useState, useEffect, useMemo, ChangeEvent, FormEvent } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ScreenSpinner from "../../components/ScreenSpinner";
import axiosInterceptor from "../../utils/axiosInterceptor";
import { Button } from "react-bootstrap";
import SelectedJobDetails from "./SelectedJobDetails";
import Job from "./types";


interface SearchParams {
    keyword: string;
    location: string;
    datePosted: string;
    experience: string;
    company: string;
    salary: string;
    remote: string;
}

const JobFinder: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [jobs, setJobs] = useState<Job[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedJob, setSelectedJob] = useState<Job | null>(null);
    const [searchParams, setSearchParams] = useState<SearchParams>({
        keyword: "",
        location: "",
        datePosted: "",
        experience: "",
        company: "",
        salary: "",
        remote: "",
    });
    const [sortOrder, setSortOrder] = useState<string>("mostRelevant");

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const paramsFromUrl: SearchParams = {
            keyword: queryParams.get("keyword") || "",
            location: queryParams.get("location") || "",
            datePosted: queryParams.get("datePosted") || "",
            experience: queryParams.get("experience") || "",
            company: queryParams.get("company") || "",
            salary: queryParams.get("salary") || "",
            remote: queryParams.get("remote") || "",
        };
        setSearchParams(paramsFromUrl);
        setSortOrder(queryParams.get("sortOrder") || "mostRelevant");
    }, [location]);

    useEffect(() => {
        fetchJobs();
    }, []);

    const fetchJobs = async () => {
        setLoading(true);
        try {
            const userId = localStorage.getItem("user_id");
            const response = await axiosInterceptor.get<Job[]>(
                `/get_unrated_active_jobs_for_user/${userId}/`
            );
            setJobs(response.data);
        } catch (error) {
            console.error("Error fetching jobs:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = (e: FormEvent) => {
        e.preventDefault();
        updateQueryParams();
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setSearchParams((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleJobSelect = (job: Job) => {
        setSelectedJob(job);
    };

    const handleSortChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setSortOrder(e.target.value);
    };

    const updateQueryParams = () => {
        const queryParams = new URLSearchParams();
        Object.entries({ ...searchParams, sortOrder }).forEach(([key, value]) => {
            if (value) queryParams.append(key, value);
        });
        navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true });
    };

    const filteredAndSortedJobs = useMemo(() => {
        const filtered = jobs.filter((job) => {
            return (
                job.title.toLowerCase().includes(searchParams.keyword.toLowerCase()) &&
                job.city.toLowerCase().includes(searchParams.location.toLowerCase()) &&
                (searchParams.datePosted === "" ||
                    isWithinDateRange(job.timestamp, searchParams.datePosted)) &&
                (searchParams.experience === "" ||
                    mapTitle(job.title) === searchParams.experience) &&
                (searchParams.company === "" ||
                    job.company_details.company_name
                        .toLowerCase()
                        .includes(searchParams.company.toLowerCase())) &&
                (searchParams.salary === "" ||
                    isWithinSalaryRange(
                        job.compensationLowerBound,
                        job.compensationUpperBound,
                        searchParams.salary
                    )) &&
                (searchParams.remote === "" ||
                    (job.city.toLowerCase() === "remote" &&
                        searchParams.remote === "true") ||
                    (job.city.toLowerCase() !== "remote" &&
                        searchParams.remote === "false"))
            );
        });

        if (sortOrder === "newest") {
            filtered.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
        } else if (sortOrder === "oldest") {
            filtered.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());
        }

        return filtered;
    }, [jobs, searchParams, sortOrder]);

    const uniqueCompanies = useMemo(() => {
        const companies = new Set(jobs.map((job) => job.company_details.company_name));
        return Array.from(companies).sort();
    }, [jobs]);

    return loading ? (
        <ScreenSpinner />
    ) : (
        <div className="flex flex-col gap-y-6 h-[88vh]">
            <h1 className="text-3xl font-medium text-left">Job Finder</h1>
            <div className="flex flex-none flex-col gap-y-2">
                <div className="flex space-x-2 mb-2 w-full justify-between">
                    <div className="flex flex-row space-x-2 w-full">
                        <div className="relative">
                            <input
                                type="text"
                                placeholder="Job title, keyword, or company"
                                className="w-[20.5rem] pl-12 pr-8 py-2 rounded"
                                name="keyword"
                                value={searchParams.keyword}
                                onChange={handleInputChange}
                            />
                            <i className="bi bi-search absolute top-1/2 transform -translate-y-1/2 left-4 text-black"></i>
                        </div>
                        <div className="relative">
                            <input
                                type="text"
                                placeholder="City (or 'remote')"
                                className="w-60  pl-12 pr-8 py-2 rounded"
                                name="location"
                                value={searchParams.location}
                                onChange={handleInputChange}
                            />
                            <i className="bi bi-geo absolute top-1/2 transform -translate-y-1/2 left-4 text-black"></i>
                        </div>
                        <Button
                            variant="outline-primary border-2 rounded-full"
                            onClick={handleSearch}
                        >
                            Search
                        </Button>
                    </div>
                </div>
                <div className="flex space-x-2">
                    <select
                        name="datePosted"
                        value={searchParams.datePosted}
                        onChange={handleInputChange}
                        className="border-2 font-medium !pl-4 border-black py-1.5 rounded-full bg-transparent padded-select-arrow"
                    >
                        <option value="" disabled>
                            Date Posted
                        </option>
                        <option value="1">Past 24 hours</option>
                        <option value="7">Past week</option>
                        <option value="30">Past month</option>
                        <option value="90">Past 3 months</option>
                    </select>
                    <select
                        name="experience"
                        value={searchParams.experience}
                        onChange={handleInputChange}
                        className="border-2 font-medium !pl-4 border-black py-1.5 rounded-full bg-transparent padded-select-arrow"
                    >
                        <option value="" disabled>
                            Experience
                        </option>
                        <option value="intern">Intern</option>
                        <option value="non-intern">Non-Intern</option>
                    </select>
                    <select
                        name="company"
                        value={searchParams.company}
                        onChange={handleInputChange}
                        className="border-2 font-medium !pl-4 border-black py-1.5 rounded-full bg-transparent padded-select-arrow"
                    >
                        <option value="">Company</option>
                        {uniqueCompanies.map((company) => (
                            <option key={company} value={company}>
                                {company}
                            </option>
                        ))}
                    </select>
                    <select
                        name="salary"
                        value={searchParams.salary}
                        onChange={handleInputChange}
                        className="border-2 font-medium !pl-4 border-black py-1.5 rounded-full bg-transparent padded-select-arrow"
                    >
                        <option value="" disabled>
                            Salary
                        </option>
                        <option value="0-50000">$0 - $50,000</option>
                        <option value="50000-100000">$50,000 - $100,000</option>
                        <option value="100000-150000">
                            $100,000 - $150,000
                        </option>
                        <option value="150000+">$150,000+</option>
                    </select>
                    <select
                        name="remote"
                        value={searchParams.remote}
                        onChange={handleInputChange}
                        className="border-2 font-medium !pl-4 border-black py-1.5 rounded-full bg-transparent padded-select-arrow"
                    >
                        <option value="" disabled>
                            Remote
                        </option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select>
                </div>
            </div>
            <div className="flex flex-grow overflow-hidden border-t-2 border-[#B4B4B4]">
                <div className="w-1/3 pr-4 overflow-y-auto">
                    <div className="border-b-2 border-[#B4B4B4] mb-2 !-mr-4 py-3 px-4 flex flex-col gap-y-2">
                        <h3 className="font-semibold">
                            Searching for{" "}
                            {searchParams.keyword.length > 0
                                ? `"${searchParams.keyword}"`
                                : "all jobs"}{" "}
                            in{" "}
                            {searchParams.location.length > 0
                                ? `"${searchParams.location}"`
                                : "any location"}{" "}
                        </h3>
                        <div className="flex flex-row justify-between items-center">
                            <p>
                                {filteredAndSortedJobs.length}{" "}
                                {filteredAndSortedJobs.length === 1
                                    ? "result"
                                    : "results"}
                            </p>
                            <select
                                className="bg-transparent text-gray-600 text-sm"
                                onChange={handleSortChange}
                                value={sortOrder}
                            >
                                <option value="mostRelevant">
                                    Most Relevant
                                </option>
                                <option value="newest">Newest</option>
                                <option value="oldest">Oldest</option>
                            </select>
                        </div>
                    </div>
                    <div className="flex flex-col gap-y-3 mt-4">
                        {filteredAndSortedJobs.map((job) => (
                            <div
                                className={`flex flex-row gap-x-4 items-start ${
                                    selectedJob?.id === job.id
                                        ? "bg-[#B1BFD8]/20"
                                        : "bg-white"
                                } p-2 rounded-lg cursor-pointer`}
                                key={job.id}
                                onClick={(e) => {
                                    handleJobSelect(job);
                                }}
                            >
                                <div className="!size-16 rounded-lg bg-[#D9D9D9]" />
                                <div className="flex flex-col gap-y-0.5 h-min w-[75%]">
                                    <div className="flex flex-row justify-between items-center">
                                        <p className="font-semibold text-black text-lg">
                                            {job.title}
                                        </p>
                                    </div>
                                    <p className="text-black font-medium">
                                        {job.company_details.company_name}
                                    </p>
                                    <p className="text-gray-700 font-medium text-sm">
                                        $
                                        {Math.round(
                                            job.compensationLowerBound / 1000
                                        )}
                                        -
                                        {Math.round(
                                            job.compensationUpperBound / 1000
                                        )}
                                        k/yr{" "}
                                    </p>
                                    <p className="text-gray-700 font-medium text-sm mt-0.5">
                                        {job.city}{" "}
                                        <span className="mx-1">&bull;</span>{" "}
                                        {new Date(
                                            job.timestamp
                                        ).toLocaleDateString()}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="w-2/3 overflow-y-auto border-l-2 border-[#B4B4B4] h-full !px-10">
                    {selectedJob ? (
                        <div className="py-4">
                            <SelectedJobDetails job={selectedJob} />
                        </div>
                    ) : (
                        <div className="flex items-center text-center w-full h-[60vh] justify-center m-auto">
                            <p className="text-lg text-black">
                                Select a job to view details
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const isWithinDateRange = (timestamp: string, range: string): boolean => {
    const jobDate = new Date(timestamp);
    const now = new Date();
    const daysAgo = (now.getTime() - jobDate.getTime()) / (1000 * 60 * 60 * 24);
    return daysAgo <= parseInt(range, 10);
};

const isWithinSalaryRange = (lower: number, upper: number, range: string): boolean => {
    const [min, max] = range.split("-").map(Number);
    return (lower >= min && lower <= max) || (upper >= min && upper <= max);
};

const mapTitle = (title: string): string => {
    return title.toLowerCase().includes("intern") ? "intern" : "non-intern";
};

export default JobFinder;