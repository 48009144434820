import React, { useState, ReactNode, CSSProperties } from "react";
import './ReadMore.css'; 

interface ReadMoreProps {
  children: string | ReactNode;
  maxLength?: number;
}

const ReadMore: React.FC<ReadMoreProps> = ({ children, maxLength = 100 }) => {
  const text = typeof children === 'string' ? children : '';
  const [isReadMore, setIsReadMore] = useState(true);

  const toggleReadMore = () => setIsReadMore(!isReadMore);

  return (
    <p className="read-more-text">
      {isReadMore ? text.slice(0, maxLength) : text}
      {typeof children === 'string' && text.length > maxLength && (
        <span onClick={toggleReadMore} className="read-or-hide" style={{ cursor: 'pointer', color: 'blue' }}>
          {isReadMore ? " Read more" : " Show less"}
        </span>
      )}
    </p>
  );
};

export default ReadMore;