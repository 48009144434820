import { useEffect, useState } from "react";
import ScreenSpinner from "../../components/ScreenSpinner";
import axiosInterceptor from "../../utils/axiosInterceptor";
import { Button } from "react-bootstrap";

interface User {
  user_id: number;
  full_name: string;
  email: string;
  resume_link?: string;
  joined: string;
}

export default function AdminDashboard() {
  const [finishedLoading, setFinishedLoading] = useState(false);
  const [userData, setUserData] = useState<User[]>([]);

  const loadUserData = () => {
    console.log("Loading");
    axiosInterceptor.get("load_all_data/");
  };

  const accept = async (user: User) => {
    try {
      await axiosInterceptor.patch(`/management/verify_user/${user.user_id}/`);
      setUserData((prevData) =>
        prevData.filter((existingUser) => existingUser.user_id !== user.user_id)
      );
    } catch (err) {
      console.error("Error accepting user:", err);
    }
  };

  const reject = async (user: User) => {
    try {
      await axiosInterceptor.delete(`/management/reject_user/${user.user_id}/`);
      setUserData((prevData) =>
        prevData.filter((existingUser) => existingUser.user_id !== user.user_id)
      );
    } catch (err) {
      console.error("Error rejecting user:", err);
    }
  };

  useEffect(() => {
    axiosInterceptor
      .get("/management/get_users_pending_verification/")
      .then((res) => {
        const sortedUsers: User[] = res.data.sort((a: User, b: User) =>
          a.full_name.localeCompare(b.full_name)
        );
        setUserData(sortedUsers);
        setFinishedLoading(true);
      })
      .catch((err) => {
        console.error("Error fetching user data:", err);
        setFinishedLoading(true);
      });
  }, []);

  if (!finishedLoading) {
    return <ScreenSpinner />;
  }

  return (
    <>
      <h1 className="text-2xl font-bold mb-6">Pending User Verifications</h1>
      <div className="overflow-x-auto">
        <Button onClick={loadUserData}>Load Data into Vectors</Button>
        <table className="min-w-full bg-white rounded-lg shadow-lg">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b font-semibold text-black text-center">
                Index
              </th>
              <th className="py-2 px-4 border-b text-left font-semibold text-black">
                Name
              </th>
              <th className="py-2 px-4 border-b text-left font-semibold text-black">
                Email
              </th>
              <th className="py-2 px-4 border-b text-left font-semibold text-black">
                Resume
              </th>
              <th className="py-2 px-4 border-b text-left font-semibold text-black">
                Joined
              </th>
              <th className="py-2 px-4 border-b"></th>
            </tr>
          </thead>
          <tbody>
            {userData.map((user, index) => (
              <tr key={user.user_id} className="hover:bg-gray-100">
                <td className="py-2 px-4 border-b text-center">{index + 1}</td>
                <td className="py-2 px-4 border-b">{user.full_name}</td>
                <td className="py-2 px-4 border-b">{user.email}</td>
                <td className="py-2 px-4 border-b">
                  <a
                    href={user.resume_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`text-blue-500 hover:underline ${
                      user.resume_link ? "" : "pointer-events-none text-gray-400"
                    }`}
                  >
                    {user.resume_link ? "View Resume" : "No Resume"}
                  </a>
                </td>
                <td className="py-2 px-4 border-b">
                  {new Date(user.joined).toLocaleDateString()}
                </td>
                <td className="py-2 px-4 border-b text-right flex flex-row gap-2 items-center w-min">
                  <Button variant="success" size="sm" onClick={() => accept(user)}>
                    Accept
                  </Button>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => {
                      if (
                        window &&
                        window.confirm(
                          `Are you sure you want to reject ${user.full_name}?`
                        )
                      ) {
                        reject(user);
                      }
                    }}
                  >
                    Reject
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}