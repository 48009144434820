import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Nav } from "react-bootstrap";
import Logo from "../../assets/img/home/logo.png";
import axiosInterceptor from "../../utils/axiosInterceptor";
import { ReactComponent as CourseMap } from "../../assets/icons/CourseMap.svg";
import { ReactComponent as Home } from "../../assets/icons/Home.svg";
import { ReactComponent as JobFinder } from "../../assets/icons/JobFinder.svg";
import { ReactComponent as Messages } from "../../assets/icons/Messages.svg";
import { ReactComponent as Profile } from "../../assets/icons/Profile.svg";
import { ReactComponent as Recruitment } from "../../assets/icons/Recruitment.svg";
import { ReactComponent as Settings } from "../../assets/icons/Settings.svg";
import { ReactComponent as TestCenter } from "../../assets/icons/TestCenter.svg";

interface HeaderProps {
    isUserLoggedIn: boolean;
    isSidebarOpen: boolean;
    toggleSidebar: (open: boolean) => void;
}

interface Page {
    icon: React.ReactNode;
    text: string;
    link: string;
    adminOnly?: boolean;
    action?: () => void;
}

const Header: React.FC<HeaderProps> = ({ isUserLoggedIn, isSidebarOpen, toggleSidebar }) => {
    const location = useLocation();

    async function logoutUser() {
        try {
            await axiosInterceptor.post(`/logout/`, {
                refresh_token: localStorage.getItem("refresh_token"),
            });
        } catch (error: any) {
            if (error.code !== "ERR_BAD_REQUEST") {
                console.error(error);
            }
        }
        localStorage.clear();
        window.location.reload();
    }

    const loggedOutBtns = (
        <Nav className="flex flex-row -space-x-2">
            <Nav.Link as={Link} to="/account/login">
                <button className="rounded-full px-4 py-1.5 border-1 border-solid border-special-blue text-special-blue font-light text-sm">
                    Log In
                </button>
            </Nav.Link>
            <Nav.Link as={Link} to="/account/signup">
                <button className="rounded-full px-4 py-1.5 bg-special-blue text-white font-light text-sm">
                    Sign Up
                </button>
            </Nav.Link>
        </Nav>
    );

    const loggedInPages: Page[] = [
        { icon: <Home />, text: "Dashboard", link: "/courses" },
        { icon: <CourseMap className="-ml-0.5" />, text: "Course Map", link: "/course-recommender" },
        { icon: <Recruitment />, text: "Recruitment", link: "/progress" },
        { icon: <JobFinder />, text: "Job Finder", link: "/job-finder" },
        { icon: <TestCenter />, text: "Test Center", link: "/test-center/home" },
        { icon: <Messages />, text: "Messages", link: "/chat" },
        { icon: <Settings className="-my-4" />, text: "Management", link: "/management", adminOnly: true },
    ];

    const accountPages: Page[] = [
        { icon: <Profile />, text: "Profile", link: "/profile" },
        { icon: <i className="bi bi-box-arrow-right ml-0.5"></i>, text: "Logout", link: "#", action: logoutUser },
    ];

    return (
        <>
            {isUserLoggedIn ? (
                <Nav
                    id="sidebar"
                    className={`flex flex-col z-20 transition-all duration-250 ease-out ${
                        isSidebarOpen
                            ? "w-max py-2 px-3 bg-white relative items-center text-center border-r"
                            : "w-min py-2 px-2 items-center text-center fixed !h-32"
                    }`}
                    style={{
                        height: `calc(100vh - 43px)`,
                    }}
                >
                    <>
                        <Nav className="flex flex-col items-center me-auto w-full h-full text-xl">
                            <div
                                className={
                                    "flex items-center justify-between " +
                                    (isSidebarOpen ? "" : "w-32")
                                }
                                onClick={(e) => toggleSidebar(!isSidebarOpen)}
                            >
                                <div className="items-center flex flex-row gap-0 p-2 cursor-pointer">
                                    <img
                                        src="/favicon.ico"
                                        alt="AlgoLink Logo"
                                        className="size-12 border border-gray-500 rounded-lg shadow-lg px-2 py-2.5 align-top d-inline-block bg-white"
                                    />
                                    <a
                                        href="/"
                                        className="flex items-center no-underline"
                                    >
                                        <span
                                            className={
                                                "text-gray-800 font-semibold text-3xl ml-4 barlow-semi-condensed-medium " +
                                                (isSidebarOpen
                                                    ? "hidden lg:block"
                                                    : "hidden")
                                            }
                                        >
                                            AlgoLink
                                        </span>
                                    </a>
                                </div>
                            </div>
                            <div
                                className={`flex flex-col ${
                                    isSidebarOpen
                                        ? "items-start"
                                        : "items-start hidden"
                                } gap-y-1 mt-6 ml-0 mr-auto w-full`}
                            >
                                {loggedInPages.filter(tab => !tab.adminOnly || (localStorage.getItem("is_algolink_admin") === "true")).map((page, index) => (
                                    <Nav.Link
                                        key={index}
                                        as={Link}
                                        to={page.link}
                                        className={
                                            "flex !font-normal items-start w-full ml-0 mr-auto gap-x-4 rounded-xl px-4 !my-1 " +
                                            (location.pathname.includes(
                                                page.link
                                            )
                                                ? "bg-grayish-blue"
                                                : "")
                                        }
                                    >
                                        <span className="text-2xl flex flex-row gap-x-3 items-center">
                                            {page.icon}{" "}
                                            <span className="text-sm font-medium">
                                                {page.text}
                                            </span>
                                        </span>
                                    </Nav.Link>
                                ))}
                            </div>
                            <div className="flex-1 flex-grow" />
                            <div
                                className={
                                    "flex flex-col items-start ml-0 mr-auto w-full gap-y-1" +
                                    " " +
                                    (isSidebarOpen ? "" : "hidden")
                                }
                            >
                                {accountPages.map((page, index) => (
                                    <Nav.Link
                                        key={index}
                                        as={Link}
                                        to={page.link}
                                        className={
                                            "flex !font-normal items-center gap-x-4 rounded-xl px-4 !my-1 w-full" +
                                            (location.pathname === page.link
                                                ? "bg-grayish-blue"
                                                : "")
                                        }
                                        onClick={page.action}
                                    >
                                        <span className="text-2xl flex flex-row gap-x-3 items-center">
                                            {page.icon}
                                            <span className="text-sm font-medium">
                                                {page.text}
                                            </span>
                                        </span>
                                    </Nav.Link>
                                ))}
                            </div>
                        </Nav>
                    </>
                </Nav>
            ) : (
                <Nav
                    id="navbar"
                    className="flex flex-col gap-y-2 lg:gap-y-0 lg:flex-row justify-center lg:justify-between items-center py-2.5 z-20"
                    style={{ backgroundColor: "white" }}
                >
                    <div className="flex items-center justify-between">
                        <a href="/#landing" className="!font-medium px-3">
                            <img
                                src={Logo}
                                alt="AlgoLink Logo"
                                height="30"
                                width="30"
                                className="align-top d-inline-block me-1 w-32"
                            />
                        </a>
                    </div>
                    <Nav className="flex items-center col-span-4 mx-4 text-sm font-light gap-x-4 lg:gap-x-12 w-max opacity-80 lg:pl-16">
                        <a
                            href="/#recruitment"
                            className="!font-medium no-underline inline-block"
                        >
                            Recruitment
                        </a>
                        <a
                            href="/#education"
                            className="!font-medium no-underline inline-block"
                        >
                            Education
                        </a>
                        <a
                            href="/#testimonials"
                            className="!font-medium no-underline inline-block"
                        >
                            Testimonials
                        </a>
                        <a
                            href="/#faq"
                            className="!font-medium no-underline inline-block"
                        >
                            FAQ
                        </a>
                    </Nav>
                    {loggedOutBtns}
                </Nav>
            )}
        </>
    );
};

export default Header;