// src/routes/profile/ProfileCard.tsx
import React, { useState, useEffect } from "react";
import Avatar from "boring-avatars";
import { Button, Modal } from "react-bootstrap";
import EditProfile from "./EditProfile";
import axiosInterceptor from "../../utils/axiosInterceptor";
import { UserData, DisplayUser } from "./types";

interface ProfileCardProps {
  user: DisplayUser;
  user_data: UserData;
}

const ProfileCard: React.FC<ProfileCardProps> = ({ user, user_data }) => {
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [university, setUniversity] = useState<string>("");
  const { name, email, phone, website, linkedin } = user;
  const { UserID } = user_data;

  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = () => setShowEdit(true);

  useEffect(() => {
    const fetchEdus = async () => {
      try {
        const response = await axiosInterceptor.get(
          `get_user_education/${UserID}/`
        );
        setUniversity(response.data[0]["school"] || "Unknown University");
      } catch (err) {
        console.error("Error fetching education data:", err);
      }
    };

    fetchEdus();
  }, [UserID]);

  return (
    <div className="flex flex-col w-full gap-y-3 p-6 rounded-lg shadow-card bg-white">
      <div className="w-2/3 mx-auto mb-4">
        <div className="mx-auto mb-4" style={{ width: '100%' }}>
          <Avatar
            name={UserID}
            variant="beam"
            size="100%"
            colors={["#99CBFF", "#EDEDED"]}
          />
        </div>
      </div>
      <h4 className="text-2xl font-medium">{name}</h4>
      <p className="text-sm font-medium -mt-3 mb-2">{university}</p>
      <div className="flex flex-col gap-y-0.5">
        <label className="text-xs font-medium text-gray-500">Email</label>
        <p className="text-lg font-medium text-black">{email}</p>
      </div>
      <div className="flex flex-col gap-y-0.5">
        <label className="text-xs font-medium text-gray-500">Phone</label>
        <p className="text-lg font-medium text-black">{phone}</p>
      </div>
      <div className="flex flex-col gap-y-0.5">
        <label className="text-xs font-medium text-gray-500">Website</label>
        <p className="text-lg font-medium text-black">{website}</p>
      </div>
      <div className="flex flex-col gap-y-0.5">
        <label className="text-xs font-medium text-gray-500">LinkedIn</label>
        <p className="text-lg font-medium text-black">{linkedin}</p>
      </div>
      <Button
        variant="light"
        className="float-right ml-auto mr-0"
        onClick={handleShowEdit}
      >
        <i className="mr-2 bi bi-pencil-square"></i>
        Edit
      </Button>
      <Modal show={showEdit} onHide={handleCloseEdit} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditProfile existingInfo={user_data} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ProfileCard;
