import React, { useEffect, useState, FC } from "react";
import { Model, SurveyModel } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import axiosInterceptor from "../utils/axiosInterceptor";

interface UploadProps {
  userId: string;
  rootDirectory: string;
  uploadEndpoint: string;
}

const Upload: FC<UploadProps> = ({ userId, rootDirectory, uploadEndpoint }) => {
  const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1];
  const [fileSelected, setFileSelected] = useState<boolean>(false);

  const json = {
    elements: [
      {
        type: "file",
        title: "Please upload your files",
        name: "files",
        storeDataAsText: false,
        waitForUpload: true,
        allowMultiple: true,
        maxSize: 104857600,
        hideNumber: true,
        backgroundColor: "#000000",
      },
    ],
  };

  const survey = new Model(json);

  const completeButton = survey.navigationBar.actions.find(
    (x) => x.id === "sv-nav-complete"
  );
  if (completeButton) {
    survey.navigationBar.actions.splice(
      survey.navigationBar.actions.indexOf(completeButton),
      1
    );
  }

  survey.showCompletedPage = true;

  survey.onComplete.add((sender: SurveyModel) => {
    console.log("On Complete");
    console.log(JSON.stringify(sender.data, null, 3));
  });

  survey.onUploadFiles.add((_, options: any) => {
    console.log("File uploaded");
    if (options.name === "files") {
      const formData = new FormData();
      options.files.forEach((file: File) => {
        formData.append("file", file);
      });

      formData.append("root_directory", `${rootDirectory}`);
      const uploadURL = `${uploadEndpoint}${userId}/`;
      console.log(uploadURL);

      axiosInterceptor
        .post(uploadURL, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-CSRFToken": csrfToken || "",
          },
        })
        .then((uploadResponse) => {
          console.log("Upload successful", uploadResponse.data);
          survey.doComplete();
          setFileSelected(true);
        })
        .catch((error) => {
          console.error("Error during file upload or processing: ", error);
        });
    }
  });

  useEffect(() => {
    if (fileSelected) {
      survey.doComplete();
      setFileSelected(false);
      window.location.reload();
    }
  }, [fileSelected, survey]);

  return <Survey model={survey} className="!bg-transparent" />;
};

export { Upload };
