import React from "react";
import { Link, useRouteError } from "react-router-dom";

interface RouteError {
    statusText?: string;
    message?: string;
}

export const Error: React.FC = () => {
    const error = useRouteError() as RouteError;

    return (
        <div>
            <div className="d-flex align-items-center justify-content-center">
                <div className="text-center">
                    <h1 className="display-1 fw-bold">Error</h1>
                    <p className="fs-3">
                        <span className="text-danger">Oops!</span>{" "}
                        {error?.message || "An unexpected error occurred."}
                    </p>
                    <Link to="/" className="btn btn-primary">
                        Go Back Home
                    </Link>
                </div>
            </div>
        </div>
    );
};