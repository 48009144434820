import React, { Fragment } from "react";
import { useLoaderData, LoaderFunctionArgs } from "react-router-dom";
import Lecture from "./lectures/Lecture";
import SEOWrapper from "../../components/SEOWrapper";
import { API_BASE_URL } from "../../constants";
import { getFilesFromDirectory, findDirectoryForLesson } from "./fileUtils";
import { ContentData, ContentTabs, ContentType } from "./types";

export default function ContentTemplate() {
    const content = useLoaderData() as ContentData;

    const descriptionBase = content.tabs[2]?.description?.[0] 
        ? content.tabs[2].description[0].replaceAll("-", " ")
        : "AlgoLink Course";

    let title: string;
    let description: string;

    if (content.type === "pdf") {
        title = `${descriptionBase} - PDF Lecture - AlgoLink`;
        description =
            "Access PDF lecture materials for your AlgoLink course. Enhance your learning with supplementary resources and discussion.";
    } else if (content.type === "video") {
        title = `${descriptionBase} - Video Lecture - AlgoLink`;
        description =
            "Watch video lectures for your AlgoLink course. Access supplementary resources and engage in course discussions.";
    } else {
        title = `${descriptionBase} - AlgoLink`;
        description =
            "Access course materials and resources for your AlgoLink course.";
    }

    return (
        <SEOWrapper title={title} description={description}>
            <Fragment>
                <Lecture content={content} />
            </Fragment>
        </SEOWrapper>
    );
}

export const contentLoader = async ({ params }: LoaderFunctionArgs) => {
    const courseId = params.courseId!;
    const lessonId = parseInt(params.lessonId!) - 1;
    const fileUrl = `https://ai-academy-storage-f951b86a124629-staging.s3.amazonaws.com/public/static/generated/${encodeURIComponent(
        courseId
    )}.json`;

    try {
        const data = await fetch(fileUrl).then((response) => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
        });

        const result = await findDirectoryForLesson(lessonId, data);
        const foundDirectory = result.foundDirectory;
        const count = result.count;

        const mainDirectoryPath = `course_materials/${courseId}/file_store_${count}/${foundDirectory}`;
        const mainFilesList = await getFilesFromDirectory(mainDirectoryPath);

        const supportDirectoryPath = `course_materials/${courseId}/support_file_store_${count}/${foundDirectory}`;
        const supportFilesList = await getFilesFromDirectory(supportDirectoryPath);

        const supportFilesUrls = supportFilesList.map(
            (supportFile) =>
                `${API_BASE_URL}/serve_file/${supportFile.replace(
                    /^\/static\//,
                    ""
                )}`
        );

        const isPdf = mainFilesList[0]?.endsWith(".pdf");
        const isMp4 = mainFilesList[0]?.endsWith(".mp4");

        const tabs: ContentTabs[] = [
            { name: "Content", description: [] },
            { name: "Resources", description: supportFilesUrls },
            { name: "Upload", description: [courseId] },
            { name: "Discussion", description: [courseId] },
        ];

        if (isPdf) {
            const content: ContentData = {
                type: "pdf",
                mainFilesList,
                tabs,
            };
            return content;
        } else if (isMp4) {
            const content: ContentData = {
                type: "video",
                mainFilesList,
                tabs,
            };
            return content;
        } else {
            return { type: "unsupported", mainFilesList: [], tabs: [] };
        }
    } catch (error) {
        console.error("Error fetching or processing JSON:", error);
        throw error;
    }
};
