// src/routes/course_recommender/CourseRecommender.tsx

import React, { useState, useEffect } from "react";
import CoursePath from "./CoursePath";
import "./CourseRecommender.css";
import { ReactFlowProvider, Node, Edge } from "react-flow-renderer";
import axiosInterceptor from "../../utils/axiosInterceptor";
import { getJsonCourseData } from "../courses/fileUtils";
import ScreenSpinner from "../../components/ScreenSpinner";
import { Dropdown } from "react-bootstrap";
import {
    Course,
    CourseProgress,
    SavedCourseTypesResponse,
    EvaluatePrereqResponse,
    JsonCourseData,
} from "./types";



const CourseRecommender: React.FC = () => {
    const [courses, setCourses] = useState<Course[]>([]);
    const [masteredCourses, setMasteredCourses] = useState<CourseProgress[]>([]);
    const [inProgressCourses, setInProgressCourses] = useState<CourseProgress[]>([]);
    const [recommendations, setRecommendations] = useState<Course[]>([]);
    const [needMorePrerequisites, setNeedMorePrerequisites] = useState<Course[]>([]);
    const [coursesProgress, setCoursesProgress] = useState<CourseProgress[]>([]);
    const [selectedCourseTypes, setSelectedCourseTypes] = useState<Record<string, boolean>>({});
    const [edges, setEdges] = useState<Edge[]>([]);
    const [nodes, setNodes] = useState<Node[]>([]);
    const [refetch, setRefetch] = useState<boolean>(false);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    const loadData = async (): Promise<Course[]> => {
        const jsonDataArray: JsonCourseData[] = await getJsonCourseData();
        const loadedCourses: Course[] = jsonDataArray.map((jsonData) => ({
            id: jsonData.courseName.replace(/ /g, "-"),
            course: jsonData.courseName,
            courseNumber: jsonData.courseNumber || 0,
            courseType: jsonData.courseType,
            prereqs: jsonData.prereqs || [],
        }));
        setCourses(loadedCourses);

        const courseTypes = [...new Set(loadedCourses.map((course) => course.courseType))];
        const user_id = localStorage.getItem("user_id");

        try {
            const response = await axiosInterceptor.get<SavedCourseTypesResponse>(
                `/get_saved_course_types_for_user/${user_id}/`
            );
            const savedTypes = response.data.saved_course_types;
            const selectedTypes = courseTypes.reduce(
                (acc, type) => ({ ...acc, [type]: savedTypes.includes(type) }),
                {} as Record<string, boolean>
            );

            if (Object.values(selectedTypes).every((value) => !value)) {
                // If no saved types are true, default all to true
                setSelectedCourseTypes(
                    courseTypes.reduce((acc, type) => ({ ...acc, [type]: true }), {} as Record<string, boolean>)
                );
            } else {
                setSelectedCourseTypes(selectedTypes);
            }
        } catch (error) {
            console.error("Error loading saved course types:", error);
            const selectedTypes = courseTypes.reduce(
                (acc, type) => ({ ...acc, [type]: true }),
                {} as Record<string, boolean>
            );
            setSelectedCourseTypes(selectedTypes);
        }

        return loadedCourses;
    };

    const getProgressData = async (): Promise<CourseProgress[]> => {
        const user_id = localStorage.getItem("user_id");
        const response = await axiosInterceptor.get<CourseProgress[]>(
            `/course-progress/${user_id}/`,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        const progressData = response.data;
        const masteredCoursesData = progressData.filter((courseEntry) => courseEntry.progress >= 1.0);
        const inProgressCoursesData = progressData.filter(
            (courseEntry) => courseEntry.progress > 0.0 && courseEntry.progress < 1.0
        );

        setInProgressCourses(inProgressCoursesData);
        setMasteredCourses(masteredCoursesData);
        setCoursesProgress(progressData);

        const setOfCourseNumbersInCoursesProgress = new Set(
            progressData.map((course) => course.courseNumber)
        );
        const coursesNotInCoursesProgress = courses
            .filter(
                (course) => !setOfCourseNumbersInCoursesProgress.has(course.courseNumber)
            )
            .map((course): CourseProgress => ({
                courseNumber: course.courseNumber,
                progress: 0.0,
            }));

        setCoursesProgress([...progressData, ...coursesNotInCoursesProgress]);

        return progressData;
    };

    const handleEvaluatePrerequisites = async (loadedCourses: Course[], progressData: CourseProgress[]) => {
        try {
            const masteredCourseNumbers = progressData
                .filter((course) => course.progress >= 1.0)
                .map((course) => course.courseNumber);

            const response = await axiosInterceptor.post<EvaluatePrereqResponse>(
                `/api/evaluate-prerequisites`,
                { masteredCourses: masteredCourseNumbers },
                { headers: { "Content-Type": "application/json" } }
            );

            const data = response.data;
            const updatedRecommendations = data.recommendations
                .map((courseNum) => loadedCourses.find((course) => course.courseNumber === courseNum))
                .filter((course): course is Course => course != null);

            setRecommendations(updatedRecommendations);

            const updatedNeedMorePrerequisites = loadedCourses.filter(
                (course) =>
                    !masteredCourseNumbers.includes(course.courseNumber) &&
                    !updatedRecommendations.some((rec) => rec.courseNumber === course.courseNumber)
            );
            setNeedMorePrerequisites(updatedNeedMorePrerequisites);
        } catch (error) {
            console.error("Error in fetching:", error);
        }
    };

    useEffect(() => {
        const fetchAllData = async () => {
            const loadedCourses = await loadData();
            const progressData = await getProgressData();
            await handleEvaluatePrerequisites(loadedCourses, progressData);
            setDataLoaded(true);
        };
        fetchAllData();
    }, [refetch]);

    const handleCourseTypeChange = async (type: string) => {
        const newSelectedTypes = { ...selectedCourseTypes, [type]: !selectedCourseTypes[type] };
        setSelectedCourseTypes(newSelectedTypes);

        const user_id = localStorage.getItem("user_id");
        const savedTypes = Object.entries(newSelectedTypes)
            .filter(([, isSelected]) => isSelected)
            .map(([courseType]) => courseType);

        try {
            await axiosInterceptor.post(
                `/set_saved_course_types_for_user/${user_id}/`,
                { saved_course_types: savedTypes }
            );
        } catch (error) {
            console.error("Error saving course types:", error);
        }
    };

    const filterCoursesByType = (coursesList: Course[]): Course[] => {
        return coursesList.filter((course) => selectedCourseTypes[course.courseType]);
    };

    useEffect(() => {
        if (dataLoaded && courses.length > 0) {
            const courseMap = courses.reduce((acc: Record<number, string>, course) => {
                acc[course.courseNumber] = course.id;
                return acc;
            }, {});
            createNodes(courseMap);
            createEdges(courseMap);
        }
    }, [
        dataLoaded,
        courses,
        recommendations,
        needMorePrerequisites,
        masteredCourses,
        inProgressCourses,
        selectedCourseTypes,
    ]);

    const setEmptyCourseProgress = async () => {
        try {
            const courseSet = courses.map((course) => course.courseNumber);
            const courseProgressSet = new Set(coursesProgress.map((course) => course.courseNumber));
            const filteredSet = courseSet.filter((course) => !courseProgressSet.has(course));

            const postBody: { courseNumber: number; progress: number }[] = [];
            filteredSet.forEach((course) => {
                postBody.push({
                    courseNumber: course,
                    progress: 0.0,
                });
            });

            // Potentially you could POST this data if needed, but it's not done in the original code
            // await axiosInterceptor.post(`/some_endpoint`, postBody);
        } catch (error) {
            console.error("Error getting course progress:", error);
        }
    };

    useEffect(() => {
        loadData().then(() => getProgressData());
    }, [refetch]);

    useEffect(() => {
        if (coursesProgress.length > 0 && courses.length > 0) {
            setEmptyCourseProgress();
        }
    }, [refetch, coursesProgress]);

    const refetchData = () => {
        setRefetch(!refetch);
    };

    useEffect(() => {
        if (courses.length > 0) {
            const courseMap = courses.reduce((acc: Record<number, string>, course) => {
                acc[course.courseNumber] = course.id;
                return acc;
            }, {});
            createNodes(courseMap);
            createEdges(courseMap);
        }
    }, [refetch, courses, recommendations, needMorePrerequisites, masteredCourses, inProgressCourses]);

    const chooseColor = (courseId: number): string => {
        if (masteredCourses.some((course) => course.courseNumber === courseId)) {
            return "#d1e7ff"; // Mastered
        } else if (inProgressCourses.some((course) => course.courseNumber === courseId)) {
            return "#fff3cd"; // In Progress
        } else if (recommendations.some((course) => course.courseNumber === courseId)) {
            return "#CCD9AB"; // Recommended
        } else {
            return "#f8d7da"; // Not started
        }
    };

    const createNodes = (courseMap: Record<number, string>) => {
        const levels: Record<number, number> = {};
        const courseLevels: Course[][] = [];

        const filteredCourses = filterCoursesByType(courses);

        const getCourseLevel = (course: Course): number => {
            if (levels[course.courseNumber] !== undefined) {
                return levels[course.courseNumber];
            }
            if (!course.prereqs || course.prereqs.length === 0) {
                levels[course.courseNumber] = 0;
                return 0;
            }

            const prereqLevels = course.prereqs.map((prereq) => {
                const prereqCourse = courses.find((c) => c.courseNumber === prereq);
                return prereqCourse ? getCourseLevel(prereqCourse) : -1;
            });
            const level = Math.max(...prereqLevels) + 1;
            levels[course.courseNumber] = level;
            return level;
        };

        filteredCourses.forEach((course) => {
            const level = getCourseLevel(course);
            courseLevels[level] = courseLevels[level] || [];
            courseLevels[level].push(course);
        });

        const temp_nodes: Node[] = [];
        const xSpacing = 200;
        const ySpacing = 120;
        const nodeWidth = 180;
        const nodeHeight = 40;

        courseLevels.forEach((levelCourses, level) => {
            if (!levelCourses) return;
            const levelWidth = levelCourses.length * (nodeWidth + xSpacing) - xSpacing;
            const startX = -levelWidth / 2;

            levelCourses.forEach((course, index) => {
                const id = course.id; // Changed to use course.id for consistency
                const position = {
                    x: startX + index * (nodeWidth + xSpacing),
                    y: level * (nodeHeight + ySpacing),
                };
                const color = chooseColor(course.courseNumber);
                const style: React.CSSProperties = {
                    backgroundColor: color,
                    borderRadius: "10px",
                    width: nodeWidth,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "12px",
                    border: "1px solid #ccc",
                };

                temp_nodes.push({
                    id: course.id,
                    data: { label: course.id },
                    position: position,
                    style: style,
                });
            });
        });

        setNodes(temp_nodes);
    };

    const createEdges = (courseMap: Record<number, string>) => {
        const temp_edges: Edge[] = [];
        const filteredCourses = filterCoursesByType(courses);

        filteredCourses.forEach((course) => {
            if (course.prereqs && course.prereqs.length > 0) {
                course.prereqs.forEach((prereq) => {
                    const prereqCourse = courses.find((c) => c.courseNumber === prereq);
                    if (
                        prereqCourse &&
                        courseMap[prereq] &&
                        filteredCourses.includes(prereqCourse)
                    ) {
                        temp_edges.push({
                            source: courseMap[prereq],
                            target: course.id,
                            id: `${courseMap[prereq]}-${course.id}`,
                            type: "smoothstep",
                            animated: true,
                            style: { stroke: "#000" },
                        });
                    }
                });
            }
        });

        setEdges(temp_edges);
    };

    return !dataLoaded ? (
        <ScreenSpinner />
    ) : (
        <div className="h-full mb-20">
            <div className="flex flex-col gap-y-2">
                <h1 className="text-3xl font-medium text-left">Course Map</h1>
                <div className="text-xs text-gray-500 lg:w-5/12">
                    Based on your preference form, we recommend the following course map
                    to stand out as a candidate for top companies in your desired field.
                </div>
            </div>
            <div className="h-full">
                <div className="h-full">
                    <div className="h-full mt-10 lg:mt-6 relative">
                        <Dropdown className="absolute top-[16px] left-[16px] z-10">
                            <Dropdown.Toggle className="rounded-lg shadow-sm active:shadow-sm bg-white border text-black font-medium !text-xs">
                                Course Type
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="mt-2 rounded-lg shadow-sm cursor-pointer !text-base !font-normal">
                                {Object.keys(selectedCourseTypes).map((type) => (
                                    <Dropdown.Item
                                        className="active:!bg-transparent focus:!bg-transparent active:!text-black focus:!text-black"
                                        key={type}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            handleCourseTypeChange(type);
                                        }}
                                    >
                                        <div className="flex items-center gap-x-2 flex-row">
                                            <input
                                                checked={selectedCourseTypes[type]}
                                                type="checkbox"
                                                className="accent-[#1B4E99] w-4 h-4 rounded"
                                                style={{
                                                    marginLeft: "-2px",
                                                    marginTop: "-2px",
                                                    fontSize: "3px",
                                                }}
                                            />
                                            {type}
                                        </div>
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                        <div className="h-full">
                            <div className="relative w-full h-full bg-white rounded-lg course-card">
                                <ReactFlowProvider>
                                    <CoursePath
                                        nodes={nodes}
                                        edges={edges}
                                        title=""
                                        courses={filterCoursesByType(courses)}
                                        coursesProgress={coursesProgress}
                                        reFetchData={refetchData}
                                    />
                                </ReactFlowProvider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CourseRecommender;
