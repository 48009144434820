import React, { useState, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import Webcam from "react-webcam";
import { Button, Container, Alert, Form } from "react-bootstrap";
import axiosInterceptor from "../../utils/axiosInterceptor";

interface RequestPayload {
    num_samples: number;
    subjects: string[];
}

const TechnicalHome: React.FC = (): JSX.Element => {
    const navigate = useNavigate();
    const [selectedSubjects, setSelectedSubjects] = useState<string[]>([]);
    const [selectedDifficulty, setSelectedDifficulty] = useState<string>("");
    const [showAlert, setShowAlert] = useState<boolean>(false);

    const handleSubjectChange = (event: ChangeEvent<HTMLSelectElement>): void => {
        const selectedValue = event.target.value;
        setSelectedSubjects((prevSelectedSubjects) => {
            if (prevSelectedSubjects.includes(selectedValue)) {
                return prevSelectedSubjects.filter((subject) => subject !== selectedValue);
            } else {
                return [...prevSelectedSubjects, selectedValue];
            }
        });
    };

    const handleDifficultyChange = (event: ChangeEvent<HTMLSelectElement>): void => {
        setSelectedDifficulty(event.target.value);
    };

    const handleStartInterview = async (): Promise<void> => {
        if (!selectedSubjects.length || !selectedDifficulty) {
            setShowAlert(true);
            return;
        }

        setShowAlert(false);

        const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1];
        const request: RequestPayload = {
            num_samples: 5,
            subjects: selectedSubjects,
        };

        try {
            const response = await axiosInterceptor.post(
                "/sample_questions/",
                request,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRFToken": csrfToken || "",
                    },
                }
            );
            navigate("/test-center/technical", { state: response.data });
        } catch (error) {
            console.error("Error during interview initiation:", error);
            navigate("/test-center/technical");
        }
    };

    return (
        <Container fluid className="min-vh-100 py-4" style={{ backgroundColor: "#ffffff" }}>
            <div className="mb-3 flex flex-col items-start gap-y-4">
                <h1 className="text-3xl font-medium text-left">Technical Exam</h1>
            </div>
            <div className="bg-white shadow-lg rounded-3xl p-4">
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                    <div className="lg:col-span-2">
                        <h5 className="mb-3 text-lg font-semibold">Instructions</h5>
                        <p className="mb-4 text-gray-800">
                            Choose a difficulty and select subjects before you start the
                            interview. This is to tailor your questions to your interests and
                            skill level.
                        </p>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label className="text-sm">Choose subjects:</Form.Label>
                                <Form.Select
                                    multiple
                                    value={selectedSubjects}
                                    onChange={handleSubjectChange}
                                    className="text-sm h-40"
                                >
                                    <option value="" disabled>
                                        --Please choose a subject--
                                    </option>
                                    <option value="mathcompetition">Math Competition</option>
                                    <option value="logicreasoning">Logic/Reasoning</option>
                                    <option value="fundamentalfinance">Fundamental Finance</option>
                                    <option value="specializedfinance">Specialized Finance</option>
                                    <option value="codingsyntaxproficiency">
                                        Coding Syntax Proficiency
                                    </option>
                                    <option value="algorithmsdatastructures">
                                        Algorithms/Data Structures
                                    </option>
                                    <option value="backend">Backend</option>
                                    <option value="gametheory">Game Theory</option>
                                    <option value="marketmaking">Market Making</option>
                                    <option value="statistics">Statistics</option>
                                    <option value="probability">Probability</option>
                                    <option value="fundamentalmath">Fundamental Math</option>
                                    <option value="basicmachinelearning">Basic Machine Learning</option>
                                    <option value="complexmachinelearning">
                                        Complex Machine Learning
                                    </option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label className="text-sm">Choose a difficulty:</Form.Label>
                                <Form.Select
                                    value={selectedDifficulty}
                                    onChange={handleDifficultyChange}
                                    className="text-sm"
                                >
                                    <option value="" disabled>
                                        --Please choose a difficulty--
                                    </option>
                                    <option value="easy">Easy</option>
                                    <option value="medium">Medium</option>
                                    <option value="hard">Hard</option>
                                </Form.Select>
                            </Form.Group>
                        </Form>
                    </div>
                    <div className="lg:col-span-1 m-auto lg:mx-0">
                        <div className="webcam-container lg:float-right">
                            <Webcam className="rounded w-full max-w-[300px]" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-4 flex flex-col sm:flex-row justify-end gap-3">
                <Button
                    onClick={handleStartInterview}
                    variant="primary"
                    className="bg-special-blue border-0 rounded-full text-sm px-4"
                >
                    Start Interview
                </Button>
            </div>
            {showAlert && (
                <Alert variant="danger">
                    Please choose a course and difficulty before starting the interview.
                </Alert>
            )}
        </Container>
    );
};

export default TechnicalHome;