// src/charts/chart.tsx
import React, {
    createContext,
    useContext,
    useId,
    useMemo,
    forwardRef,
    ReactElement,
  } from "react";
  import {
    ResponsiveContainer,
    Tooltip,
    Legend,
    TooltipProps as RechartsTooltipProps,
    LegendProps as RechartsLegendProps,
  } from "recharts";
  import { cn } from "../lib/utils";
  import { ChartConfig, ChartConfigItem, ChartContextType } from "./types";
  
  const THEMES: { [key: string]: string } = {
    light: "",
    dark: ".dark",
  };
  
  interface CSSPropertiesExtended extends React.CSSProperties {
    [key: `--${string}`]: string;
  }
  
  const ChartContext = createContext<ChartContextType | null>(null);
  
  function useChart(): ChartContextType {
    const context = useContext(ChartContext);
    if (!context) {
      throw new Error("useChart must be used within a <ChartContainer />");
    }
    return context;
  }
  
  interface ChartContainerProps extends React.HTMLAttributes<HTMLDivElement> {
    id?: string;
    className?: string;
    children: ReactElement;
    config: ChartConfig;
  }
  
  const ChartContainer = forwardRef<HTMLDivElement, ChartContainerProps>(
    ({ id, className, children, config, ...props }, ref) => {
      const uniqueId = useId();
      const chartId = `chart-${id || uniqueId.replace(/:/g, "")}`;
  
      return (
        <ChartContext.Provider value={{ config }}>
          <div
            data-chart={chartId}
            ref={ref}
            className={cn(
              "flex aspect-video justify-center text-xs [&_.recharts-cartesian-axis-tick_text]:fill-muted-foreground [&_.recharts-cartesian-grid_line[stroke='#ccc']]:stroke-border/50 [&_.recharts-curve.recharts-tooltip-cursor]:stroke-border [&_.recharts-dot[stroke='#fff']]:stroke-transparent [&_.recharts-layer]:outline-none [&_.recharts-polar-grid_[stroke='#ccc']]:stroke-border [&_.recharts-radial-bar-background-sector]:fill-muted [&_.recharts-rectangle.recharts-tooltip-cursor]:fill-muted [&_.recharts-reference-line_[stroke='#ccc']]:stroke-border [&_.recharts-sector[stroke='#fff']]:stroke-transparent [&_.recharts-sector]:outline-none [&_.recharts-surface]:outline-none",
              className
            )}
            {...props}
          >
            <ChartStyle id={chartId} config={config} />
            <ResponsiveContainer>{children}</ResponsiveContainer>
          </div>
        </ChartContext.Provider>
      );
    }
  );
  
  ChartContainer.displayName = "Chart";
  
  interface ChartStyleProps {
    id: string;
    config: ChartConfig;
  }
  
  const ChartStyle: React.FC<ChartStyleProps> = ({ id, config }) => {
    const colorConfig = Object.entries(config).filter(
      ([_, configItem]) => configItem.theme || configItem.color
    );
  
    if (!colorConfig.length) {
      return null;
    }
  
    return (
      <style
        dangerouslySetInnerHTML={{
          __html: Object.entries(THEMES)
            .map(
              ([theme, prefix]) => `
    ${prefix} [data-chart=${id}] {
    ${colorConfig
      .map(([key, itemConfig]) => {
        const color = itemConfig.theme?.[theme] || itemConfig.color;
        return color ? `  --color-${key}: ${color};` : "";
      })
      .join("\n")}
    }
    `
            )
            .join("\n"),
        }}
      />
    );
  };
  
  const ChartTooltip = Tooltip;
  
  interface ChartTooltipContentProps extends RechartsTooltipProps<any, any> {
    hideLabel?: boolean;
    hideIndicator?: boolean;
    labelFormatter?: (value: string, payload: any) => React.ReactNode;
    labelClassName?: string;
    formatter?: (
      value: any,
      name: string,
      item: any,
      index: number,
      payload: any
    ) => React.ReactNode;
    color?: string;
    nameKey?: string;
    labelKey?: string;
    indicator?: string;
    className?: string;
  }
  
  const ChartTooltipContent = forwardRef<HTMLDivElement, ChartTooltipContentProps>(
    (
      {
        active,
        payload,
        className,
        indicator = "dot",
        hideLabel = false,
        hideIndicator = false,
        label,
        labelFormatter,
        labelClassName,
        formatter,
        color,
        nameKey,
        labelKey,
      },
      ref
    ) => {
      const { config } = useChart();
      const tooltipLabel = useMemo(() => {
        if (hideLabel || !payload?.length) {
          return null;
        }
        const [item] = payload;
        const key = `${labelKey || item.dataKey || item.name || "value"}`;
        const itemConfig = getPayloadConfigFromPayload(config, item, key);
        const value =
          !labelKey && typeof label === "string"
            ? config[label]?.label || label
            : itemConfig?.label;
        if (labelFormatter) {
          return (
            <div className={cn("font-medium", labelClassName)}>
              {labelFormatter(value ?? "", payload)}
            </div>
          );
        }
        if (!value) {
          return null;
        }
        return <div className={cn("font-medium", labelClassName)}>{value}</div>;
      }, [
        label,
        labelFormatter,
        payload,
        hideLabel,
        labelClassName,
        config,
        labelKey,
      ]);
  
      if (!active || !payload?.length) {
        return null;
      }
  
      const nestLabel = payload.length === 1 && indicator !== "dot";
  
      return (
        <div
          ref={ref}
          className={cn(
            "grid min-w-[8rem] items-start gap-1.5 rounded-lg border border-border/50 bg-background px-2.5 py-1.5 text-xs shadow-xl",
            className
          )}
        >
          {!nestLabel ? tooltipLabel : null}
          <div className="grid gap-1.5">
            {payload.map((item: any, index: number) => {
              const key = `${nameKey || item.name || item.dataKey || "value"}`;
              const itemConfig = getPayloadConfigFromPayload(config, item, key);
              const indicatorColor = color || item.payload.fill || item.color;
  
              return (
                <div
                  key={item.dataKey}
                  className={cn(
                    "flex w-full flex-wrap items-stretch gap-2 [&>svg]:h-2.5 [&>svg]:w-2.5 [&>svg]:text-muted-foreground",
                    indicator === "dot" && "items-center"
                  )}
                >
                  {formatter && item?.value !== undefined && item.name ? (
                    formatter(item.value, item.name, item, index, item.payload)
                  ) : (
                    <>
                      {itemConfig?.icon ? (
                        <itemConfig.icon />
                      ) : (
                        !hideIndicator && (
                          <div
                            className={cn(
                              "shrink-0 rounded-[2px] border-[--color-border] bg-[--color-bg]",
                              {
                                "h-2.5 w-2.5": indicator === "dot",
                                "w-1": indicator === "line",
                                "w-0 border-[1.5px] border-dashed bg-transparent":
                                  indicator === "dashed",
                                "my-0.5":
                                  nestLabel && indicator === "dashed",
                              }
                            )}
                            style={{
                              "--color-bg": indicatorColor,
                              "--color-border": indicatorColor,
                            } as React.CSSProperties}
                          />
                        )
                      )}
                      <div
                        className={cn(
                          "flex flex-1 justify-between leading-none",
                          nestLabel ? "items-end" : "items-center"
                        )}
                      >
                        <div className="grid gap-1.5">
                          {nestLabel ? tooltipLabel : null}
                          <span className="text-muted-foreground">
                            {itemConfig?.label || item.name}
                          </span>
                        </div>
                        {item.value && (
                          <span className="font-mono font-medium tabular-nums text-foreground">
                            {item.value.toLocaleString()}
                          </span>
                        )}
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  );
  
  ChartTooltipContent.displayName = "ChartTooltip";
  
  const ChartLegend = Legend;
  
  // Remove generics from RechartsLegendProps since it's not generic.
  interface ChartLegendContentProps extends RechartsLegendProps {
    hideIcon?: boolean;
    nameKey?: string;
    className?: string;
    payload?: any[];
    verticalAlign?: "top" | "middle" | "bottom";
  }
  
  const ChartLegendContent = forwardRef<HTMLDivElement, ChartLegendContentProps>(
    ({ className, hideIcon = false, payload, verticalAlign = "bottom", nameKey }, ref) => {
      const { config } = useChart();
      if (!payload?.length) {
        return null;
      }
      return (
        <div
          ref={ref}
          className={cn(
            "flex items-center justify-center gap-4",
            verticalAlign === "top" ? "pb-3" : "pt-3",
            className
          )}
        >
          {payload.map((item: any) => {
            const key = `${nameKey || item.dataKey || "value"}`;
            const itemConfig = getPayloadConfigFromPayload(config, item, key);
            return (
              <div
                key={item.value}
                className={cn(
                  "flex items-center gap-1.5 [&>svg]:h-3 [&>svg]:w-3 [&>svg]:text-muted-foreground"
                )}
              >
                {itemConfig?.icon && !hideIcon ? (
                  <itemConfig.icon />
                ) : (
                  <div
                    className="h-2 w-2 shrink-0 rounded-[2px]"
                    style={{
                      backgroundColor: item.color,
                    }}
                  />
                )}
                {itemConfig?.label}
              </div>
            );
          })}
        </div>
      );
    }
  );
  
  ChartLegendContent.displayName = "ChartLegend";
  
  function getPayloadConfigFromPayload(
    config: ChartConfig,
    payload: any,
    key: string
  ): ChartConfigItem | undefined {
    if (typeof payload !== "object" || payload === null) {
      return undefined;
    }
    const payloadPayload =
      "payload" in payload &&
      typeof payload.payload === "object" &&
      payload.payload !== null
        ? payload.payload
        : undefined;
    let configLabelKey = key;
    if (key in payload && typeof payload[key] === "string") {
      configLabelKey = payload[key];
    } else if (
      payloadPayload &&
      key in payloadPayload &&
      typeof payloadPayload[key] === "string"
    ) {
      configLabelKey = payloadPayload[key];
    }
    return configLabelKey in config ? config[configLabelKey] : config[key];
  }
  
  export {
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
    ChartLegend,
    ChartLegendContent,
    ChartStyle,
  };
  