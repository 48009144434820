// src/charts/StackedBar.tsx

import React, { FC } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  Legend as RechartsLegend,
} from "recharts";
import {
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "./chart";
import { ChartConfig } from "./types";

// Define StackedBarProps interface
interface StackedBarProps {
  chartData: any[]; // Replace 'any' with a more specific type if possible
  chartConfig: ChartConfig;
  xKey: string;
  showLegend?: boolean;
}

// StackedBar component with proper typing
const StackedBar: FC<StackedBarProps> = ({
  chartData,
  chartConfig,
  xKey,
  showLegend = true,
}) => {
  return (
    <ChartContainer
      config={chartConfig}
      className="!h-60 !w-full !py-10 !px-10"
    >
      <BarChart data={chartData} className="!py-2 lg:!py-0">
        <CartesianGrid vertical={false} />
        <YAxis tickLine={false} axisLine={false} tickMargin={8} tickCount={3} />
        <XAxis
          dataKey={xKey}
          tickLine={false}
          tickMargin={10}
          axisLine={false}
          tickFormatter={(value: string) => value.slice(0, 3)}
        />
        <RechartsTooltip content={<ChartTooltipContent hideLabel />} />
        {showLegend && (
          <RechartsLegend
            content={<ChartLegendContent />}
            verticalAlign="top"
            layout="horizontal"
            align="center"
            className="!mx-auto !pl-10"
          />
        )}
        {Object.keys(chartConfig).map((key) => (
          <Bar
            dataKey={key}
            stackId="a"
            className="h-full"
            key={key}
            fill={`var(--color-${key})`}
            markerWidth={4}
            strokeWidth={3}
            barSize={6}
          />
        ))}
      </BarChart>
    </ChartContainer>
  );
};

export default StackedBar;
