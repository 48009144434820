import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./TestCenter.css";
import ProgressLines from "../../charts/ProgressLines";
import { InfoCircle } from "react-bootstrap-icons";
import { Accordion } from "react-bootstrap";
import { ArrowRight } from "lucide-react";

interface TestAttempt {
    type: string;
    score: number;
    maxScore: number;
    minutesDuration: number;
    date: Date;
}

interface TestCenterCardProps {
    color: string;
    page: string;
    title: string;
    time: string;
    description: string;
}

export default function TestCenter(): JSX.Element {
    const navigate = useNavigate();
    const [showHoverCard, setShowHoverCard] = useState<boolean>(false);

    const gotToNewPage = (url: string): void => {
        navigate(url);
    };

    const TestCenterCard: React.FC<TestCenterCardProps> = ({
        color,
        page,
        title,
        time,
        description,
    }) => (
        <div
            className={
                "p-4 text-black bg-white rounded-2xl flex flex-col justify-between cursor-pointer border !border-[2.5px] !border-opacity-100 " +
                color
            }
        >
            <div>
                <h2 className="font-semibold text-xl">{title}</h2>
                <p className="text-black text-base">est. {time} minutes</p>
            </div>
            <div className="mt-8">
                <p className="text-black tracking-tight text-sm">
                    {description}
                </p>
            </div>
            <button
                className="text-sm flex gap-x-2 items-center text-center ml-auto mr-0 mt-2 rounded-2xl text-black hover:bg-[#D9D9D9] p-2"
                onClick={() => gotToNewPage("/test-center/" + page)}
            >
                Start Testing <ArrowRight className="size-5" />
            </button>
        </div>
    );

    const background_description =
        "Sharpen your responses to questions about your education, work experience, and personal projects in our Background interview simulation. This section helps you articulate your professional journey and highlight key achievements to make a strong first impression.";
    const behavioral_description =
        "Prepare for common behavioral interview questions in our Behavioral interview simulation. Practice answering questions that assess your teamwork, leadership, and problem solving abilities, ensuring you can effectively demonstrate your soft skills and past experiences.";
    const technical_description =
        "Hone your technical interview skills in our Technical interview simulation. This section offers practice questions in math, finance, coding, and problem-solving to help you excel in technical assessments and demonstrate your expertise in your field.";

    const chartData = [
        { day: "Mon", background: 0, behavioral: 1, technical: 1 },
        { day: "Tue", background: 1, behavioral: 2, technical: 1 },
        { day: "Wed", background: 2, behavioral: 2.3, technical: 2.2 },
        { day: "Thu", background: 2.3, behavioral: 2.7, technical: 4.4 },
        { day: "Fri", background: 3, behavioral: 4, technical: 5 },
        { day: "Sat", background: 4, behavioral: 4.5, technical: 6 },
        { day: "Sun", background: 5, behavioral: 6, technical: 7 },
    ];

    const chartConfig = {
        background: { label: "Background", color: "hsl(var(--chart-1))" },
        behavioral: { label: "Behavioral", color: "hsl(var(--chart-2))" },
        technical: { label: "Technical", color: "hsl(var(--chart-3))" },
    };

    const dummyAttempts: TestAttempt[] = [
        { type: "Background", score: 23, maxScore: 45, minutesDuration: 28, date: new Date("2022-12-26") },
        { type: "Background", score: 23, maxScore: 45, minutesDuration: 28, date: new Date("2022-12-12") },
        { type: "Background", score: 23, maxScore: 45, minutesDuration: 28, date: new Date("2022-12-12") },
        { type: "Background", score: 23, maxScore: 45, minutesDuration: 28, date: new Date("2022-12-29") },
        { type: "Technical", score: 23, maxScore: 45, minutesDuration: 28, date: new Date("2022-12-12") },
        { type: "Technical", score: 23, maxScore: 45, minutesDuration: 28, date: new Date("2022-12-12") },
    ].sort((a, b) => {
        if (a.type === b.type) {
            return a.date.getTime() - b.date.getTime();
        } else {
            return a.type.localeCompare(b.type);
        }
    });

    const testTypes = ["Background", "Behavioral", "Technical"];

    return (
        <div className="px-4 lg:px-0">
            <h1 className="flex text-2xl lg:text-3xl font-medium text-left">
                Test Center
            </h1>
            <div className="pt-8 lg:pt-12 flex flex-col flex-grow">
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-y-6 lg:gap-y-0 lg:gap-x-[20px]">
                    <div className="lg:col-span-2 flex flex-col gap-y-4">
                        <p className="text-gray-700 text-base ml-2">
                            Select a test to begin
                        </p>
                        <div className="grid grid-cols-1 lg:grid-cols-3 gap-y-4 lg:gap-y-0 lg:gap-x-3">
                            <TestCenterCard
                                page="background"
                                color="!border-[#325C8C]"
                                title="Background"
                                time="30"
                                description={background_description}
                            />
                            <TestCenterCard
                                page="behavioral"
                                color="!border-[#198754]"
                                title="Behavioral"
                                time="30"
                                description={behavioral_description}
                            />
                            <TestCenterCard
                                page="technical-home"
                                color="!border-[#BF5738]"
                                title="Technical"
                                time="60"
                                description={technical_description}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col gap-y-4">
                        <p className="text-gray-700 text-base ml-2">
                            Test Details
                        </p>
                        <div className="p-4 h-full text-black bg-white rounded-2xl flex flex-col">
                            <h1 className="text-xl font-semibold">
                                Test Instructions
                            </h1>
                            <div className="prose">
                                <ol className="mt-[30px] !font-medium !text-sm">
                                    <li style={{ listStyleType: "numeric" }}>
                                        Choose which type of test you would like
                                        to take, and click the "Start Testing"
                                        button when ready.
                                    </li>
                                    <li style={{ listStyleType: "numeric" }}>
                                        Make sure to follow the prompts to turn
                                        on your webcam and microphone once you
                                        start your test.
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-y-6 lg:gap-y-0 lg:gap-x-[20px] mt-8 lg:mt-0">
                    <div className="lg:col-span-2 flex flex-col gap-y-4">
                        <p className="text-gray-700 text-base ml-2 mt-4 lg:mt-10">
                            Progress Chart
                        </p>
                        <div className="rounded-2xl bg-white p-2 lg:p-4">
                            <ProgressLines
                                chartConfig={chartConfig}
                                chartData={chartData}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col gap-y-4">
                        <div className="text-gray-700 text-base ml-2 mt-4 lg:mt-10 flex items-center relative">
                            Result Details{" "}
                            <InfoCircle
                                className="inline-block ml-2 cursor-pointer"
                                onMouseEnter={() => setShowHoverCard(true)}
                                onMouseLeave={() => setShowHoverCard(false)}
                                onClick={() => setShowHoverCard(!showHoverCard)}
                            />
                            {showHoverCard && (
                                <div className="absolute top-8 z-10 text-sm w-full lg:w-2/3 left-0 bg-white p-2 shadow border rounded-xl text-wrap break-all text-justify select-none font-normal">
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipisicing elit.
                                </div>
                            )}
                        </div>
                        <div className="rounded-2xl bg-white h-60 overflow-y-scroll p-4 flex flex-col gap-y-2">
                            {testTypes.map((tp, idx) => (
                                <Accordion key={idx}>
                                    <Accordion.Header className="w-full focus:!bg-transparent rounded-lg !opacity-100 p-0">
                                        <span className="font-medium text-black text-lg">
                                            {tp} Test
                                        </span>
                                    </Accordion.Header>
                                    <Accordion.Body className="!-my-4 flex flex-col gap-y-4">
                                    {dummyAttempts
                                    .filter((attempt) => attempt.type === tp)
                                    .map((attempt, i) => (
                                        <Accordion key={i} className="break-inside-avoid-column !border-none">
                                        {/* Provide an eventKey prop */}
                                        <Accordion.Item eventKey={`${i}`} className="w-full !rounded-xl">
                                            <Accordion.Header className="w-full !visible !opacity-100 !bg-[#6589B2] !text-white white-plus-style">
                                            <span className="font-medium text-white !text-sm">
                                                Attempt {i + 1}
                                            </span>
                                            </Accordion.Header>
                                            <Accordion.Body className="flex flex-col gap-y-2 !visible !opacity-100 !border-4 !border-[#6589B2] !rounded-b-xl">
                                                            <div className="flex flex-row justify-between">
                                                                <p className="font-medium text-black">
                                                                    Score:
                                                                </p>
                                                                <p className="text-black">
                                                                    {
                                                                        attempt.score
                                                                    }
                                                                    /
                                                                    {
                                                                        attempt.maxScore
                                                                    }{" "}
                                                                    questions
                                                                </p>
                                                            </div>
                                                            <div className="flex flex-row justify-between">
                                                                <p className="font-medium text-black">
                                                                    Duration:
                                                                </p>
                                                                <p className="text-black">
                                                                    {
                                                                        attempt.minutesDuration
                                                                    }{" "}
                                                                    minutes
                                                                </p>
                                                            </div>
                                                            <div className="flex flex-row justify-between">
                                                                <p className="font-medium text-black">
                                                                    Date:
                                                                </p>
                                                                <p className="text-black">
                                                                    {attempt.date.toLocaleDateString()}{" "}
                                                                </p>
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            ))}
                                    </Accordion.Body>
                                </Accordion>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}