// src/routes/courses/Courses.tsx
import React, { useState, useEffect } from "react";
import { Accordion, Dropdown } from "react-bootstrap";
import { getJsonCourseData } from "./fileUtils";
import { Link } from "react-router-dom";
import Avatar from "boring-avatars";
import ScreenSpinner from "../../components/ScreenSpinner";
import StackedBar from "../../charts/StackedBar";
import axiosInterceptor from "../../utils/axiosInterceptor";
import paths from "../../lib/paths";
import { Course, Path, LeaderboardEntry, PathCourse } from "./types";

const Courses: React.FC = () => {
  const [courses, setCourses] = useState<Course[]>([]);
  const [filter, setFilter] = useState<string>("");
  const [finishedLoading, setFinishedLoading] = useState<boolean>(false);
  const [selectedView, setSelectedView] = useState<string>("To-Do");
  const [selectedPathName, setSelectedPathName] = useState<string | null>(null);
  const [universityToStudentCount, setUniversityToStudentCount] = useState<Map<string, number>>(new Map());
  const [selectedUniversity, setSelectedUniversity] = useState<string | null>(null);
  const [selectedCourseTypes, setSelectedCourseTypes] = useState<Record<string, boolean>>({});
  const [coursePaths, setCoursePaths] = useState<Path[]>([]);
  const [userProgress, setUserProgress] = useState<Record<string, number>>({});

  const userName =
    (localStorage.getItem("user_name") &&
     localStorage.getItem("user_name")!.trim().length > 0
      ? localStorage.getItem("user_name")
      : "User")!
      .split(" ")[0];

  const leaderboard: Record<string, LeaderboardEntry[]> = {
    "Data Science": [
      { name: "Jack Cook", school: "Cornell University", lessons: 19 },
      { name: "Christian Lizardi", school: "Cornell University", lessons: 20 },
      { name: "Simon Ilincev", school: "Cornell University", lessons: 30 },
      { name: "Nick Awertschenko", school: "Dartmouth University", lessons: 10 },
    ],
    "Software Engineer": [
      { name: "Simon Ilincev", school: "Cornell University", lessons: 30 },
      { name: "Nick Awertschenko", school: "Dartmouth University", lessons: 10 },
      { name: "Jack Cook", school: "Cornell University", lessons: 19 },
      { name: "Christian Lizardi", school: "Cornell University", lessons: 20 },
    ],
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        // Fetch and set courses
        const jsonDataArray = await getJsonCourseData();
        const loadedCourses: Course[] = jsonDataArray.map((jsonData: any) => ({
          id: jsonData.courseName.replace(/ /g, "-"),
          course: jsonData.courseName,
          courseNumber: String(jsonData.courseNumber || ""),  // Convert to string
          courseType: jsonData.courseType,
        }));
        setCourses(loadedCourses);

        // Determine unique course types and set all as selected
        const courseTypes = [...new Set(loadedCourses.map((course) => course.courseType))];
        const selectedTypes = courseTypes.reduce((acc, type) => ({
          ...acc,
          [type]: true,
        }), {});
        setSelectedCourseTypes(selectedTypes);

        // Fetch user progress
        const response = await axiosInterceptor.get(`/course-progress/${localStorage.getItem("user_id")}/`);
        const progressData = response.data;
        const progressMap: Record<string, number> = progressData.reduce((acc: Record<string, number>, item: any) => {
          // Ensure keys are strings
          acc[String(item.courseNumber)] = item.progress;
          return acc;
        }, {});
        setUserProgress(progressMap);

        // Update paths with progress information
        const updatedPaths: Path[] = paths.map((path) => {
          const totalUnits = path.courses.length;
          const completedUnits = path.courses.reduce((sum, course) => {
            const progress = progressMap[course.courseid] || 0;
            return sum + (progress === 1 ? 1 : 0);
          }, 0);

          const inProgress = path.courses.some((course) => {
            const progress = progressMap[course.courseid] || 0;
            return progress > 0 && progress < 1;
          });

          const inProgressUnits = path.courses.filter((course) => {
            const progress = progressMap[course.courseid] || 0;
            return progress > 0 && progress < 1;
          }).length;

          return {
            ...path,
            totalUnits,
            completedUnits,
            inProgress,
            inProgressUnits,
          };
        });
        setCoursePaths(updatedPaths);

      } catch (error: any) {
        console.error("Error loading courses:", error.message);
      }
      setFinishedLoading(true);
    };

    // Compute university to student count based on leaderboard
    const newUniversityToStudentCount = new Map<string, number>();
    for (const [pathType, students] of Object.entries(leaderboard)) {
      for (const student of students) {
        const key = `${pathType}-${student.school}`;
        newUniversityToStudentCount.set(key, (newUniversityToStudentCount.get(key) || 0) + 1);
      }
    }
    setUniversityToStudentCount(newUniversityToStudentCount);

    // Load data
    loadData();
  }, []);

  const selectableViews = ["To-Do", "Started", "Completed"];

  const chartConfig = {
    dataScience: {
      label: "DS",
      color: "hsl(var(--chart-1))",
    },
    softwareEngineer: {
      label: "SWE",
      color: "hsl(var(--chart-2))",
    },
    quant: {
      label: "Quant",
      color: "hsl(var(--chart-3))",
    },
  };

  const chartData = [
    { day: "Mon", dataScience: 2, softwareEngineer: 3, quant: 1 },
    { day: "Tue", dataScience: 3, softwareEngineer: 2, quant: 1 },
    { day: "Wed", dataScience: 2, softwareEngineer: 0, quant: 3 },
    { day: "Thu", dataScience: 1, softwareEngineer: 2, quant: 2 },
    { day: "Fri", dataScience: 3, softwareEngineer: 1, quant: 1 },
    { day: "Sat", dataScience: 2, softwareEngineer: 2, quant: 2 },
    { day: "Sun", dataScience: 1, softwareEngineer: 3, quant: 0 },
  ];

  // Filter courses based on selected course types
  const filteredCourses = courses.filter(
    (course) => selectedCourseTypes[course.courseType] || false
  );
  filteredCourses.sort((a, b) => a.course.localeCompare(b.course));

  // Group courses by their type
  const groupCoursesByType = (
    courses: Course[]
  ): Record<string, Course[]> => {
    return courses.reduce((acc, course) => {
      if (!acc[course.courseType]) {
        acc[course.courseType] = [];
      }
      acc[course.courseType].push(course);
      return acc;
    }, {} as Record<string, Course[]>);
  };

  const groupedCourses = groupCoursesByType(courses);

  // Extract unique universities from leaderboard
  const possibleUniversities = Array.from(
    new Set(
      Object.values(leaderboard)
        .flatMap((e) => e)
        .map((e) => e.school)
    )
  );

  // State for selected leaderboard view
  const [selectedLeaderboardView, setSelectedLeaderboardView] = useState<string>(
    Object.keys(leaderboard)[0]
  );

  // Function to get university count based on selected leaderboard view
  const getUniversityCount = (university: string): number => {
    return (
      universityToStudentCount.get(
        `${selectedLeaderboardView}-${university}`
      ) || 0
    );
  };

  const selectableLeaderboardViews = Object.keys(leaderboard);

  // Function to get filtered courses for a specific path
  const getFilteredCoursesForPath = (pathName: string): Course[] => {
    const path = paths.find((p) => p.name === pathName);
    if (!path) return [];

    return path.courses
      .flatMap((pathCourse) => {
        const course = courses.find((c) => c.courseNumber === pathCourse.courseid);
        // If you want to merge pathCourse data as JS does:
        return course ? [{ ...course, ...pathCourse }] : [];
      })
      .filter((course) =>
        course.course.toLowerCase().includes(filter.toLowerCase())
      );
  };

  return !finishedLoading ? (
    <ScreenSpinner />
  ) : (
    <div className="flex flex-col gap-y-8">
      <section className="flex flex-col lg:flex-row gap-y-2 lg:gap-y-0 lg:gap-x-2 items-center lg:-mx-2">
        <div className="rounded-xl bg-white shadow-md w-full h-full row-span-9 xl:col-span-9 grid xl:grid-cols-4 gap-y-6 xl:gap-x-6 items-center place-items-center py-4 px-4 flex-grow">
          <div className="size-40">
            <Avatar
              name="John Doe"
              variant="beam"
              size="100%"
              colors={["#99CBFF", "#EDEDED"]}
            />
          </div>
          <div className="xl:col-span-2 flex flex-col gap-y-4">
            <h3 className="text-2xl font-medium">
              Hi {userName}, what do you want to learn today?
            </h3>
            <div className="relative">
              <input
                type="text"
                placeholder="Search by course name"
                className="w-full rounded-full pl-14 pr-8 py-2 bg-gray-100"
                onChange={(e) => setFilter(e.target.value)}
              />
              <i className="bi bi-search absolute top-1/2 transform -translate-y-1/2 left-6 text-gray-400"></i>
            </div>
          </div>
          <div
            className={
              "grid grid-rows-3 gap-y-4 h-min my-auto ml-10 place-self-center mx-auto text-center w-[10rem]"
            }
          >
            <div className="grid grid-cols-3 gap-x-2 items-center border rounded-lg !border-black !opacity-100 py-2 px-2">
              <div className="items-center gap-x-4 font-semibold text-4xl text-right mr-1">
                24
              </div>
              <div className="text-gray-600 text-sm text-wrap col-span-2 text-left">
                Hours completed
              </div>
            </div>
            <div className="grid grid-cols-3 gap-x-2 items-center border rounded-lg !border-black !opacity-100 py-2 px-2">
              <div className="items-center gap-x-4 font-semibold text-4xl text-right mr-1">
                30
              </div>
              <div className="text-gray-600 text-sm text-wrap col-span-2 text-left">
                Courses completed
              </div>
            </div>
            <div className="grid grid-cols-3 gap-x-2 items-center border rounded-lg !border-black !opacity-100 py-2 px-2">
              <div className="items-center gap-x-4 font-semibold text-4xl text-right mr-1">
                3
              </div>
              <div className="text-gray-600 text-sm text-wrap col-span-2 text-left">
                Courses in progress
              </div>
            </div>
          </div>
        </div>
        <div className="rounded-xl bg-white shadow-md row-span-3 xl:col-span-3 place-items-center h-full items-center md:pr-10 w-full lg:w-80 justify-center my-auto flex">
          <div className="items-center !justify-center !my-auto h-min w-full">
            <StackedBar
              chartConfig={chartConfig}
              chartData={chartData}
              showLegend={window.innerWidth >= 768}
              xKey="day"
            />
          </div>
        </div>
      </section>
      <section className="flex flex-col gap-y-4">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row space-x-4">
            {selectableViews.map((view) => (
              <button
                key={view}
                className={`${
                  selectedView === view
                    ? "text-black"
                    : "text-gray-400"
                } font-semibold text-lg`}
                onClick={() => setSelectedView(view)}
              >
                {view}
              </button>
            ))}
          </div>
        </div>
        <div className="grid grid-row-12 lg:grid-rows-none lg:grid-cols-12 my-auto justify-center gap-y-2 lg:gap-x-2 lg:gap-y-0 h-full !w-full">
          <div className="row-span-7 lg:col-span-7 grid grid-cols-2 gap-2 h-min">
            {coursePaths
              .filter((path) =>
                selectedView === "Started"
                  ? path.inProgress ||
                    (path.completedUnits > 0 &&
                     path.completedUnits < path.totalUnits)
                  : selectedView === "To-Do"
                  ? path.completedUnits === 0 && !path.inProgress
                  : path.completedUnits === path.totalUnits
              )
              .sort(
                (a, b) =>
                  a.completedUnits / a.totalUnits -
                  b.completedUnits / b.totalUnits
              )
              .map((path, i) => (
                <div
                  key={i}
                  className={
                    "rounded-xl px-4 py-3 bg-white shadow-md space-y-2 cursor-pointer " +
                    (selectedPathName === path.name
                      ? "border border-3 border-opacity-100 !border-[#1B4E99]"
                      : "")
                  }
                  onClick={() =>
                    selectedPathName && path.name === selectedPathName
                      ? setSelectedPathName(null)
                      : setSelectedPathName(path.name)
                  }
                >
                  <div className="flex flex-row justify-between">
                    <div className="rounded-full bg-[#426EB2] size-14 mt-2 mb-4 items-center justify-center text-white text-4xl text-center flex">
                      <div className="text-center m-auto">
                        {React.createElement(
                          path.icon,
                          { size: 24 }
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row justify-between">
                    <div className="font-semibold text-lg">
                      {path.name}
                    </div>
                  </div>
                  <div className="w-full h-1 bg-gray-200 rounded-full">
                    <div
                      className="h-full bg-green-500 rounded-full"
                      style={{
                        width: `${((path.completedUnits +
                          path.inProgressUnits) /
                          path.totalUnits) *
                          100}%`,
                      }}
                    ></div>
                    {/* add a lil circle */}
                    <div
                      className="h-3 w-3 bg-green-500 rounded-full -mt-2"
                      style={{
                        position: "relative",
                        left: `${((path.completedUnits +
                          path.inProgressUnits) /
                          path.totalUnits) *
                          100 -
                          2}%`,
                      }}
                    ></div>
                  </div>
                  <div className="bg-gray-100 rounded-full text-xs w-min px-2 py-1 float-end">
                    {path.completedUnits + path.inProgressUnits}/{path.totalUnits}
                  </div>
                </div>
              ))}
          </div>
          <div className="row-span-5 lg:col-span-5 rounded-xl px-4 py-3 bg-white shadow-md space-y-2">
            <div className="font-medium text-xl">
              Leaderboard for {selectedLeaderboardView}
            </div>
            <div className="flex flex-row justify-between my-3">
              <div className="flex flex-row space-x-4">
                {selectableLeaderboardViews.map((view) => (
                  <button
                    key={view}
                    className={`${
                      selectedLeaderboardView === view
                        ? "text-black"
                        : "text-gray-400"
                    } font-semibold`}
                    onClick={() =>
                      setSelectedLeaderboardView(view)
                    }
                  >
                    {view}
                  </button>
                ))}
              </div>
              <div className="flex-grow"></div>
              <Dropdown autoClose="outside">
                <Dropdown.Toggle
                  variant="ghost"
                  className="!flex !flex-row !items-center justify-end gap-x-2 !border-none !font-medium"
                >
                  <div className="!mr-auto !ml-0 tracking-normal">
                    <i className="bi bi-funnel"></i>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="!border-none mt-2 !py-0 shadow !w-max !font-medium">
                  {possibleUniversities
                    .sort(
                      (a, b) =>
                        getUniversityCount(b) -
                        getUniversityCount(a)
                    )
                    .filter((el) => getUniversityCount(el) > 0)
                    .map((schoolName) => (
                      <div
                        key={schoolName}
                        className="!px-2.5 !py-1.5 !flex !items-center !gap-x-2.5 hover:!bg-gray-100 cursor-pointer !text-black !w-full"
                        id={schoolName}
                        onClick={() => {
                          if (schoolName === selectedUniversity) {
                            setSelectedUniversity(null);
                          } else {
                            setSelectedUniversity(schoolName);
                          }
                        }}
                      >
                        <input
                          type="radio"
                          className="special-radio"
                          checked={selectedUniversity === schoolName}
                          readOnly
                        />
                        {schoolName} (
                        {getUniversityCount(schoolName)})
                      </div>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="flex flex-col gap-y-4">
              {leaderboard[selectedLeaderboardView]
                .filter(
                  (person) =>
                    selectedUniversity === null ||
                    person.school === selectedUniversity
                )
                .map((person, i) => (
                  <div
                    key={i}
                    className="flex flex-row items-center gap-x-4"
                  >
                    <p className="font-semibold text-lg text-black w-2 text-left">
                      {i + 1}
                    </p>
                    <div className="rounded-full size-10">
                      <Avatar
                        name={person.name}
                        variant="beam"
                        size="100%"
                        colors={["#99CBFF", "#EDEDED"]}
                      />
                    </div>
                    <div className="flex flex-col gap-y-1">
                      <div className="text-lg">
                        {person.name}
                      </div>
                      <div className="text-xs text-gray-400">
                        {person.school}
                      </div>
                    </div>
                    <div className="flex-grow" />
                    <div className="bg-gray-100 rounded-full text-nowrap text-xs px-2 py-1 float-end">
                      +{person.lessons} lessons
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      <section
        className={
          "rounded-xl grid grid-cols-1 lg:grid-cols-2 gap-10 space-4 bg-white shadow-md " +
          (selectedPathName ? "!grid-cols-1" : "")
        }
        style={{
          padding: "27px",
        }}
      >
        {selectedPathName ? (
          <Accordion
            className="break-inside-avoid-column !border-none"
            defaultActiveKey="0"
          >
            <Accordion.Item className="w-full" eventKey="0">
              <Accordion.Header className="w-full focus:!bg-transparent border-[1.5px] rounded !opacity-100 p-0 !border-black">
                <span className="font-medium text-black">
                  {selectedPathName}
                </span>
              </Accordion.Header>
              <Accordion.Body className="!visible !bg-transparent columns-1 md:columns-2 space-y-2">
                {getFilteredCoursesForPath(selectedPathName).map((course, i) => (
                  <div key={i} className="mb-2">
                    <Link
                      className="cursor-pointer !no-underline block !font-medium hover:!underline"
                      to={`/courses/${course.id}/lectures/1`}
                    >
                      {course.course}
                    </Link>
                  </div>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        ) : (
          Object.keys(groupedCourses)
            .filter(
              (type) =>
                groupedCourses[type].some((course) =>
                  course.course
                    .toLowerCase()
                    .includes(filter.toLowerCase())
                ) ||
                type.toLowerCase().includes(filter.toLowerCase())
            )
            .map((type) => (
              <Accordion
                key={type}
                className="break-inside-avoid-column !border-none"
                defaultActiveKey="0"
              >
                <Accordion.Item className="w-full" eventKey="0">
                  <Accordion.Header className="w-full focus:!bg-transparent border-[1.5px] rounded !opacity-100 p-0 !border-black">
                    <span className="font-medium text-black">
                      {type}
                    </span>
                  </Accordion.Header>
                  <Accordion.Body className="!visible !bg-transparent columns-1 md:columns-2 space-y-2">
                    {groupedCourses[type]
                      .filter((course) =>
                        course.course
                          .toLowerCase()
                          .includes(filter.toLowerCase())
                      )
                      .map((course, i) => (
                        <Link
                          key={i}
                          className="cursor-pointer !no-underline block !font-normal hover:!underline"
                          to={`/courses/${course.id}/lectures/1`}
                        >
                          {course.course}
                        </Link>
                      ))}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ))
        )}
      </section>
    </div>
  );
};

export default Courses;