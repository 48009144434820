// src/components/career/Transcript.tsx

import React from "react";
import { TranscriptProps, CreateTransProps } from "../types";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import { Upload } from "../../../components/Upload";

const TranscriptComponent: React.FC<TranscriptProps> = ({ transcript }) => {
  return (
    <div>
      {transcript.map((trans, index) => (
        <a
          key={index}
          className="transcript-link one-line"
          href="https://google.com" // Replace with actual transcript URL
          target="_blank"
          rel="noopener noreferrer"
        >
          {trans.course}
        </a>
      ))}
    </div>
  );
};

const CreateTrans: React.FC<CreateTransProps> = ({ transcript, userId }) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData();
    transcript.forEach((trans, index) => {
      formData.append(`transcript_${index}`, trans.grade || ""); // Adjust as needed
    });
    // Add additional form submission logic here
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Stack gap={2}>
        <Upload
          userId={userId}
          rootDirectory="transcripts"
          uploadEndpoint={`file_upload/`}
        />
        {/* Add a submit button if needed */}
        {/* <button type="submit">Submit</button> */}
      </Stack>
    </Form>
  );
};

export default TranscriptComponent;
export { CreateTrans };
