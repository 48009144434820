import React from "react";
import { ContentData } from "../types";

interface PdfIframeProps {
    pdfUrl: string;
}

export function PdfIframe({ pdfUrl }: PdfIframeProps): JSX.Element {
    return (
        <div
            className="overflow-auto mt-3 d-flex flex-column align-items-center bg-dark"
            style={{ maxWidth: "100%", height: "100vh", overflowY: "auto" }}
        >
            <iframe
                title="PDF Viewer"
                src={pdfUrl}
                style={{
                    width: "100%",
                    height: "100%",
                }}
                frameBorder="0"
            >
                This browser does not support PDFs. Please download the PDF to
                view it: <a href={pdfUrl}>Download PDF</a>.
            </iframe>
        </div>
    );
}

interface VideoProps {
    videoUrl: string;
}

export function Video({ videoUrl }: VideoProps): JSX.Element {
    return (
        <video controls className="mx-auto h-full rounded-2xl w-full">
            <source src={videoUrl} type="video/mp4" />
            Sorry, your browser doesn't support embedded videos.
        </video>
    );
}

interface ResourcesProps {
    content: ContentData;
    mainDownloadURL: string;
}

export function Resources({ content, mainDownloadURL }: ResourcesProps): JSX.Element {
    const resourcesTab = content.tabs.find((tab) => tab.name === "Resources");
    const resourcesDescription = resourcesTab?.description || [];

    const titleCase = (input: string): string => {
        return input
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
    };

    const mainFileName = mainDownloadURL.split("/").pop() ?? "";
    const displayName = titleCase(
        mainFileName.replace(/_/g, " ").split(".")[0] || ""
    )
        .replace("Problems", "Problem")
        .replace("Notes", "Note")
        .replace("Videos", "Video");

    return (
        <div
            className="tab px-3 py-2 flex-fill overflow-auto"
            style={{
                maxWidth: "100%",
                maxHeight: "100%",
                overflowY: "auto",
                marginBottom: "100px",
            }}
        >
            <ul className="pl-4 list-disc">
                <li>
                    <a href={mainDownloadURL} target="_blank" rel="noreferrer">
                        {displayName}
                    </a>
                </li>
                {resourcesDescription.map((url, index) => (
                    <li key={index}>
                        <a href={url} target="_blank" rel="noreferrer">
                            {`Support File ${index + 1}`}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
}
