import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { Resources, PdfIframe, Video } from "./LectureTabs";
import { ContentData } from "../types";

interface LectureProps {
    content: ContentData;
}

export default function Lecture({ content }: LectureProps) {
    const s3BaseUrl = "https://ai-academy-storage-f951b86a124629-staging.s3.amazonaws.com/";
    const encodedFilePath = encodeURIComponent(content.mainFilesList[0]).replace(
        /%2F/g,
        "/"
    );
    const url = `${s3BaseUrl}${encodedFilePath}`;

    const location = useLocation();
    const tabNames = content.tabs.map((tab) => tab.name);

    const getCurrentTab = useCallback((): string => {
        const currentPath = location.pathname.split("/").pop() || "";
        return (
            tabNames.find((tab) => tab.toLowerCase() === currentPath.toLowerCase()) ||
            tabNames[0]
        );
    }, [location.pathname, tabNames]);

    const [activeTab, setActiveTab] = useState<string>(getCurrentTab());

    useEffect(() => {
        setActiveTab(getCurrentTab());
    }, [getCurrentTab]);

    return (
        <div className="mx-auto my-auto items-center justify-center w-full h-full">
            <div className="tab overflow-auto w-full h-full">
                {activeTab === "Content" && content.type === "pdf" && (
                    <div className="p-2 lg:p-10">
                        <PdfIframe pdfUrl={url} />
                    </div>
                )}
                {activeTab === "Content" && content.type === "video" && (
                    <Video videoUrl={url} />
                )}
                {activeTab === "Resources" && (
                    <div className="p-2 lg:p-10">
                        <Resources content={content} mainDownloadURL={url} />
                    </div>
                )}
                {activeTab === "Content" && content.type === "unsupported" && (
                    <div className="p-2 lg:p-10">Unsupported content type.</div>
                )}
            </div>
        </div>
    );
}
